import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { endOfToday, startOfToday, subMonths } from 'date-fns';
import { OrderApi } from '../../api/order';
import { Configuration } from '../../api/product';

const FinancialOverviewPage: FC = ({}) => {
  const { t } = useTranslation(['common']);
  const { auth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [marginData, setMarginData] = useState<any[]>([]);

  const [period, setPeriod] = useState<{ from: Date; until: Date }>({
    from: subMonths(startOfToday(), 3),
    until: endOfToday(),
  });

  const orderApi = useMemo(
    () =>
      new OrderApi(
        new Configuration({
          basePath: process.env.REACT_APP_SERVICE_URL_ORDER,
          accessToken: auth?.jwt,
        }),
      ),
    [auth?.jwt],
  );

  return <>FinView</>;
};

export default FinancialOverviewPage;
