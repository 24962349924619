import Sortable from '../../formbuilder/Sortable';
import { BOMItem, BOMItemValue } from './BOMItem';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { FC, useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISortable } from '../../formbuilder/utils/interfaces';
import { useBOMContext } from './BOMContext';
import { useTranslation } from 'react-i18next';

export const BOMSpecEditor: FC<{
  className?: string;
  onSave?: (BomItemData: BOMItemValue[]) => void | Promise<void>;
}> = ({ className, onSave }) => {
  const bomCtx = useBOMContext();
  const { t } = useTranslation(['common']);

  const [actionDisabled, setActionDisabled] = useState<boolean>(false);
  
  const placeholder = useMemo(
    () => (
      <div
        className={classNames('placeholder', {
          hide: (bomCtx?.activeBOM?.length ?? 0) > 0,
        })}
      >
        <div>{t('bom-placeholder-default')}</div>
      </div>
    ),
    [t, bomCtx?.activeBOM],
  );

  const processSort = useCallback(
    (sortable: ISortable): void => {
      if (bomCtx) {
        bomCtx.swapBOMItems(sortable.dragIndex, sortable.hoverIndex);
      }
    },
    [bomCtx],
  );

  if (!bomCtx) throw Error('Unable to use BOMSpecEditor outside of BOMContext');

  return (
    <>
      <div className={`${className} sortable flex flex-column gap-3 p-1`}>
        {bomCtx?.activeBOM?.map((b, i) => {
          return (
            <Sortable
              className="flex flex-row gap-3 align-items-center sortable-item "
              onDrag={processSort}
              id={`bi-${i}-srt`}
              index={i}
              key={`bi-${i}-srt`}
            >
              <div className="text-lg text-primary w-3rem">#{i + 1}</div>

              <BOMItem
                mode={bomCtx.mode}
                value={b}
                key={`bi-${i}`}
                onUpdate={(newValues) => {
                  bomCtx.updateBOMItem(i, newValues);
                }}
                onGetDataSet={() => []}
                _onDestroy={() => bomCtx.removeBOMItem(i)}
              />
            </Sortable>
          );
        })}
        {placeholder}
        <div className="action-bar">
          <button className="p-btn" onClick={() => bomCtx.addNewBOMItem()}>
            <FontAwesomeIcon icon="plus" /> {t('add')}
          </button>
        </div>
      </div>
      <div className="mt-3 p-3 bg-white">
        <Button
          className="py-2"
          disabled={actionDisabled}
          onClick={async () => {
            setActionDisabled(true);
            try {
              if (onSave) {
                const result = onSave(bomCtx.activeBOM ?? []);
                if (result instanceof Promise) {
                  await result;
                }
              }
            } finally {
              setActionDisabled(false);
            }
          }}
          icon={<span className={`pr-2 pi pi-save`} />}
        >
          {t('common:actions.save')}
        </Button>
      </div>
    </>
  );
};
