import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Dialog, DialogProps } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { StockStorageLocationDto, StockWarehouseDto } from '../../../api/core-client';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import * as yup from 'yup';
import * as yupLocales from 'yup-locales';

export interface AddEditLocationDialogProps extends DialogProps {
  mode?: 'ADD' | 'EDIT';
  locationData?: StockStorageLocationDto;
  onSave?: (data?: StockStorageLocationDto) => void | Promise<void>;
}

const AddEditLocationDialog: FC<AddEditLocationDialogProps> = (dialogprops) => {
  const {
    children,
    header,
    footer,
    className,
    mode = 'ADD',
    locationData,
    onSave,
    ...otherprops
  } = dialogprops;
  const { t, i18n } = useTranslation(['common', 'stock']);

  useEffect(() => {
    let lng = i18n.language?.substring(0,2).toLowerCase();
    if (yupLocales[lng]) {
        yup.setLocale(yupLocales[lng]);
    } else {
        yup.setLocale(yupLocales.en);
    }
  }, [i18n.language]);

  const formikRef =
    useRef<FormikProps<{ id: number; warehouseId: number, name: string; locationType: string }>>(
      null,
    );

  const _header =
    header ??
    (mode == 'ADD'
      ? t('stock:dialog.add-location.add-location')
      : t('stock:dialog.add-location.edit-location'));
  const _footer =
    footer ??
    useMemo(
      () => (
        <div className="w-full flex flex-row gap-2">
          <Button
            icon="pi pi-save mr-2"
            onClick={async () => {
              await formikRef.current?.submitForm();
            }}
          >
            {t('common:save')}
          </Button>
          <Button icon="pi pi-times mr-2" onClick={() => otherprops.onHide()}>
            {t('common:cancel')}
          </Button>
        </div>
      ),
      [formikRef.current, otherprops.onHide],
    );
  const _className = classNames(className, 'w-full sm:w-6');

  const formInitialValues = useMemo(() => {
    return {
      id: locationData?.id ?? 0,
      warehouseId: locationData?.warehouseId ?? 0,
      name: locationData?.name ?? '',
      locationType: locationData?.locationType ?? '',
    };
  }, [locationData]);

  const _handleSubmit = useCallback(
    async (
      values: typeof formInitialValues,
      helpers: FormikHelpers<typeof formInitialValues>,
    ) => {
      try {
        if (onSave) {
          await onSave({
            id: mode == 'ADD' ? 0 : values.id,
            warehouseId: values.warehouseId,
            locationType: values.locationType,
            name: values.name,
          });
        }
      } finally {
        helpers?.setSubmitting(false);
      }
    },
    [formInitialValues],
  );

  return (
    <Dialog
      {...otherprops}
      className={_className}
      header={_header}
      footer={_footer}
    >
      <div className="w-full p-3">
        <Formik
          innerRef={formikRef}
          initialValues={formInitialValues}
          onSubmit={_handleSubmit}
          validateOnChange
          validationSchema={yup.object({
            id: yup.number().required().min(0),
            name: yup.string().required(),
            locationType: yup.string().required(),
          })}
        >
          {(props) => (
            <form id="frmWarehouse" onSubmit={props.handleSubmit}>
              <div className="w-full">
                <div className="field grid">
                  <label
                    htmlFor={props.getFieldProps('name').name}
                    className="col-12 mb-2 sm:col-3 sm:mb-0"
                  >
                    {t('common:name')}
                  </label>
                  <div className="col-12 sm:col-9">
                    <InputText {...props.getFieldProps('name')} />
                    {props.errors['name'] ? <small className="danger">{props.errors['name']}</small> : undefined }
                  </div>
                </div>
                <div className="field grid">
                  <label
                    htmlFor={props.getFieldProps('locationType').name}
                    className="col-12 mb-2 sm:col-3 sm:mb-0"
                  >
                    {t('stock:locationType')}
                  </label>
                  <div className="col-12 sm:col-9">
                    <InputText
                      {...props.getFieldProps('locationType')}
                    />
                    {props.errors['locationType'] ? <small className="danger">{props.errors['locationType']}</small> : undefined }
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export { AddEditLocationDialog };
