import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useAuth } from './useAuth';
import {
  StockManagementApi,
  Configuration as CoreClientConfig,
  StockStorageLocationDto,
  StockWarehouseDto,
  StockCategoryDto,
  StockArticleDto,
  StockArticleSummaryDto,
  BillOfMaterialsApi,
  BillOfMaterialTemplateDto,
} from '../api/core-client';

export const useBOM = () => {
  const { auth } = useAuth();

  const _coreConfig = useMemo(
    () =>
      new CoreClientConfig({
        basePath: process.env.REACT_APP_SERVICE_URL_CORE,
        apiKey: auth?.jwt,
      }),
    [auth?.jwt],
  );

  const _bomApi = useMemo(
    () => new BillOfMaterialsApi(_coreConfig),
    [_coreConfig],
  );

  const ListBOMTemplatesForProduct = async (productId: number) => {
    let result: BillOfMaterialTemplateDto[] = [];

    try {
      const resp = await _bomApi.billOfMaterialsGetBOMTemplatesForProduct(
        productId,
      );

      if (resp.status == 200) {
        result = resp.data;
      }
    } catch (e) {
      result = [];
    }

    return result;
  };

  const UpdateBOMTemplateForProduct = async (
    productId: number,
    bomtemplate: BillOfMaterialTemplateDto,
  ) => {
    try {
      if (
        !productId ||
        !bomtemplate ||
        !bomtemplate.templateContent ||
        !bomtemplate.name
      )
        return;

      if (!bomtemplate?.id) {
        const resp = await _bomApi.billOfMaterialsAddBOMTemplateForProduct(
          productId,
          { ...bomtemplate, id: 0 },
        );
        if (resp.status == 200) {
          return resp.data;
        }
      } else {
        const resp = await _bomApi.billOfMaterialsUpdateBOMTemplateForProduct(
          productId,
          bomtemplate,
        );
        if (resp.status == 200) {
          if (resp.data == true) {
            return bomtemplate;
          }
        }
      }
    } catch (e) {
      console.warn('Unable to upsert BOM Template', { e });
    }
    return undefined;
  };

  return {
    ListBOMTemplatesForProduct,
    UpdateBOMTemplateForProduct,
  };
};

export const useStock = () => {
  const { auth } = useAuth();

  const [categories, setCategories] = useState<StockCategoryDto[]>([]);
  const [warehouses, setWarehouses] = useState<StockWarehouseDto[]>([]);
  const [storageLocations, setStorageLocations] = useState<
    StockStorageLocationDto[]
  >([]);

  const _coreConfig = useMemo(
    () =>
      new CoreClientConfig({
        basePath: process.env.REACT_APP_SERVICE_URL_CORE,
        apiKey: auth?.jwt,
      }),
    [auth?.jwt],
  );

  const _stockApi = useMemo(
    () => new StockManagementApi(_coreConfig),
    [_coreConfig],
  );

  const ListWarehouses = useCallback(
    async (page?: number, pageSize: number = 25) => {
      var data: StockWarehouseDto[] = [];

      try {
        const response = await _stockApi.stockManagementGetWarehouses(
          page,
          pageSize,
        );
        if (
          response.status == 200 &&
          response.data !== undefined &&
          response.data !== null
        ) {
          data = response.data;
        }
      } catch {
        data = [];
      }

      return data;
    },
    [_stockApi],
  );

  const AddWarehouse = useCallback(
    async (data: StockWarehouseDto) => {
      if (!data.name || (data.id && data.id > 0)) return;

      try {
        const response = await _stockApi.stockManagementAddNewWarehouse({
          id: 0,
          description: data.description,
          name: data.name,
        });

        if (response.status === 200) {
          let newWarehouse = response.data;
          setWarehouses((old) => [...old, newWarehouse]);
          return true;
        }
      } catch {
        return false;
      }
    },
    [_stockApi],
  );

  const UpdateWarehouse = useCallback(
    async (warehouseId: number, data: Omit<StockWarehouseDto, 'id'>) => {
      if (!data.name || !warehouseId || warehouseId <= 0) return;

      try {
        const response = await _stockApi.stockManagementUpdateWarehouseById(
          warehouseId,
          {
            id: warehouseId,
            description: data.description,
            name: data.name,
          },
        );

        if (response.status === 200) {
          if (response.data === true) {
            setWarehouses((old) =>
              old.map((s) => {
                if (s.id === warehouseId) {
                  s.name = data.name;
                  s.description = data.description;
                }
                return s;
              }),
            );
            return true;
          }
        }
      } catch {}
      return false;
    },
    [_stockApi],
  );

  const DeleteWarehouse = useCallback(
    async (
      warehouseId: number,
      force: boolean = false,
    ): Promise<[boolean, string?] | undefined> => {
      if ((warehouseId ?? 0) <= 0) return;

      try {
        let response = await _stockApi.stockManagementDeleteWarehouseById(
          warehouseId,
          { params: { force } },
        );
        if (response.status === 409) {
          // no can do..
          return [false, 'E_FORCE_REQUIRED'];
        } else if (response.status === 200) {

          if (response.data === true) {
            setWarehouses((old)=> old.filter(x=>x.id != warehouseId))
          }

          return [response.data];
        }
      } catch {
        return [false, 'E_GENERAL_FAILURE'];
      }
      return [false];
    },
    [_stockApi],
  );

  const ListLocationsForWarehouse = useCallback(
    async (warehouseId: number, page?: number, pageSize: number = 25) => {
      var data: StockStorageLocationDto[] = [];
      try {
        const response = await _stockApi.stockManagementGetWarehouseLocations(
          warehouseId,
          page,
          pageSize,
        );
        if (
          response.status == 200 &&
          response.data !== undefined &&
          response.data !== null
        ) {
          data = response.data;
        }
      } catch {
        data = [];
      }
      return data;
    },
    [_stockApi],
  );

  const ListCategories = useCallback(async () => {
    var data: StockCategoryDto[] = [];

    try {
      const response = await _stockApi.stockManagementGetCategories();
      if (
        response.status == 200 &&
        response.data !== undefined &&
        response.data !== null
      ) {
        data = response.data;
      }
    } catch {
      data = [];
    }
    return data;
  }, [_stockApi]);

  const GetAvailableArticleLocations = useCallback(
    async (articleId?: number) => {
      if (!articleId) return [];
      if (articleId <= 0) return [];
      let result: (StockStorageLocationDto & { amount?: number })[] = [];

      try {
        const artLocResponse =
          await _stockApi.stockManagementGetArticleLocations(articleId);
        if (artLocResponse.status === 200) {
          for (const al of artLocResponse.data) {
            const found = storageLocations.find((p) => p.id == al.locationId);
            if (found) {
              result.push({ ...found, amount: al.amount });
            }
          }
        }
      } catch {
        result = [];
      }

      return result;
    },
    [_stockApi, storageLocations],
  );

  const ListArticlesForCategoryId = useCallback(
    async (categoryId?: number, fullDetails?: boolean) => {
      var data: (StockArticleDto | StockArticleSummaryDto)[] = [];

      if (categoryId && categoryId <= 0) return [];

      try {
        const response = await _stockApi.stockManagementGetArticles(
          categoryId,
          fullDetails,
        );
        if (
          response.status == 200 &&
          response.data !== undefined &&
          response.data !== null
        ) {
          data = response.data;
        }
      } catch {
        data = [];
      }
      return data;
    },
    [_stockApi],
  );

  const ListArticlesOnLocation = useCallback(
    async (warehouseId: number, locationId: number) => {
      let data: StockArticleDto[] = [];
      if (!warehouseId || warehouseId <= 0 || !locationId || locationId <= 0)
        return [];

      try {
        const response =
          await _stockApi.stockManagementGetWarehouseLocationArticles(
            warehouseId,
            locationId,
          );
        if (
          response.status == 200 &&
          response.data !== undefined &&
          response.data !== null
        ) {
          data = response.data;
        }
      } catch {
        data = [];
      }

      return data;
    },
    [_stockApi],
  );


  const AddLocation = useCallback(
    async (data: StockStorageLocationDto) => {
      if (!data.name || !data.warehouseId || (data.id && data.id > 0)) return;

      try {
        const response = await _stockApi.stockManagementAddWarehouseLocations(data.warehouseId, {
          id: 0,
          warehouseId: data.warehouseId,
          locationType: data.locationType,
          name: data.name,
        });

        if (response.status == 200)
          return response.data;

      } catch {
        return false;
      }
      return false;
    },
    [_stockApi],
  );

  const UpdateLocation = useCallback(
    async (locationId: number, data: Omit<StockStorageLocationDto, 'id'>) => {
      if (!data.name || !data.warehouseId || !locationId || locationId <= 0) return;

      try {
        const response = await _stockApi.stockManagementUpdateWarehouseLocationDetails(
          data.warehouseId,
          locationId,
          {
            id: locationId,
            locationType: data.locationType,
            warehouseId: data.warehouseId,
            name: data.name,
          },
        );

        if (response.status === 200) {
            return response.data;
        }
      } catch {}
      return false;
    },
    [_stockApi],
  );

  const DeleteLocation = useCallback(
    async (
      warehouseId: number,
      locationId: number,
      force: boolean = false,
    ): Promise<[boolean, string?] | undefined> => {
      if ((warehouseId ?? 0) <= 0 || (locationId ?? 0) <= 0) return;

      try {
        let response = await _stockApi.stockManagementDeleteWarehouseLocationById(
          warehouseId,
          locationId,
          force
        );
        if (response.status === 409) {
          // no can do..
          return [false, 'E_FORCE_REQUIRED'];
        } else if (response.status === 200) {

          return [response.data];
        }
      } catch {
        return [false, 'E_GENERAL_FAILURE'];
      }
      return [false];
    },
    [_stockApi],
  );





  useLayoutEffect(() => {
    if (_stockApi) {
      Promise.all([
        _stockApi.stockManagementGetCategories(),
        _stockApi.stockManagementGetWarehouses(),
      ]).then(([respCat, respWH]) => {
        if (respCat.status === 200) {
          setCategories(respCat.data);
        }

        if (respWH.status == 200) {
          setWarehouses(respWH.data);
        }
      });
    }
  }, [_stockApi]);

  useLayoutEffect(() => {
    setStorageLocations([]);
    for (let w of warehouses) {
      if (!w.id) continue;
      _stockApi.stockManagementGetWarehouseLocations(w.id!).then((x) =>
        setStorageLocations((old) => {
          old.push(...x.data);
          return old;
        }),
      );
    }
  }, [warehouses]);

  return {
    ListWarehouses,
    ListLocationsForWarehouse,
    ListCategories,
    ListArticlesForCategoryId,
    GetAvailableArticleLocations,
    ListArticlesOnLocation,
    AddWarehouse,
    UpdateWarehouse,
    DeleteWarehouse,
    AddLocation,
    UpdateLocation,
    DeleteLocation,

    categories,
    warehouses,
  };
};
