import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BOMItemValue } from './BOMItem';
import { FormField } from '../../formbuilder/utils/interfaces';

interface BOMContextProperties {
  activeBOM?: BOMItemValue[];
  //formDefinition?: FormField[];
  mode?: 'TEMPLATE' | 'EDIT' | 'VIEW';
  addNewBOMItem: () => void;
  removeBOMItem: (idx: number) => void;
  updateBOMItem: (idx: number, value: BOMItemValue) => void;
  swapBOMItems: (idxSource: number, idxTarget: number) => void;
  setBOMMode: (mode: 'TEMPLATE' | 'EDIT' | 'VIEW') => void;
}

const BOMContext = createContext<BOMContextProperties | undefined>(undefined);

const BOMContextProvider = ({
  bomDefinition,
  //formSpecification,
  mode = 'TEMPLATE',
  children,
}: {
  bomDefinition: BOMItemValue[] | string;
  //formSpecification?: FormField[] | string;
  mode?: 'TEMPLATE' | 'EDIT' | 'VIEW';
  children?: ReactNode;
}) => {
  const [activeBOMItems, setActiveBOMItems] = useState<BOMItemValue[]>();
  const [activeBOMMode, setActiveBOMMode] = useState<
    'TEMPLATE' | 'EDIT' | 'VIEW'
  >(mode);

  // logic

  useEffect(() => {
    setActiveBOMMode(mode);

    if (bomDefinition) {
      let arr: BOMItemValue[] = [];      
      let struct;
      if (typeof bomDefinition == 'string') {
        struct = JSON.parse(bomDefinition);
      } else {
        struct = bomDefinition;
      }

      if (Array.isArray(struct)) {
        for (let elem of struct) {
          let bi = (elem as BOMItemValue);
          if (bi && bi.articleId && bi.categoryId) {
            arr.push(bi);
          }
        }
        setActiveBOMItems(arr);
      }

    }
  }, [mode, bomDefinition]);

  let contextValues = useMemo<BOMContextProperties>(() => {
    return {
      activeBOM: activeBOMItems,
      mode: activeBOMMode,
      addNewBOMItem: () => {
        setActiveBOMItems((old) => {
          let _new = [...(old ?? []), {}];
          return _new;
        });
      },
      removeBOMItem: (idx) => {
        if (activeBOMItems?.length ?? 0 > idx) {
          setActiveBOMItems((old) => {
            let _new = [...(old ?? [])];
            if (_new.length > idx) {
              _new = _new.filter((v, i) => i != idx);
            }
            return _new;
          });
        }
      },
      updateBOMItem: (idx, value) => {
        if (activeBOMItems?.length ?? 0 > idx) {
          setActiveBOMItems((old) => {
            let _new = [...(old ?? [])];
            if (_new.length > idx) {
              _new[idx] = value;
            }
            return _new;
          });
        }
      },
      setBOMMode: (mode) => {
        setActiveBOMMode(mode);
      },
      swapBOMItems: (idxSource, idxTarget) => {
        setActiveBOMItems((old) => {
          let bomItems = [
            ...(old ?? []).map((v, i, a) => {
              if (i == idxSource) {
                return { ...a[idxTarget] };
              }
              if (i == idxTarget) {
                return { ...a[idxSource] };
              }
              return v;
            }),
          ];

          return bomItems;
        });
      },
    };
  }, [activeBOMMode, activeBOMItems, setActiveBOMMode, setActiveBOMItems]);

  // if (formSpecification !== undefined) {
  //   contextValues.formDefinition = undefined;
  //   if (typeof formSpecification == 'string') {
  //     try {
  //       let deserialized = JSON.parse(formSpecification);
  //       if (Array.isArray(deserialized)) {
  //         contextValues.formDefinition = deserialized as FormField[];
  //       } else {
  //         contextValues.formDefinition = undefined;
  //       }
  //     } catch {
  //       contextValues.formDefinition = undefined;
  //     }
  //   } else {
  //     contextValues.formDefinition = formSpecification;
  //   }
  // } else {
  //   contextValues.formDefinition = undefined;
  // }

  return (
    <BOMContext.Provider value={contextValues}>{children}</BOMContext.Provider>
  );
};

const useBOMContext = () => {
  const ctx = useContext(BOMContext);
  return ctx;
};

export { BOMContext, BOMContextProvider, useBOMContext };
export type { BOMContextProperties };
