/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * Facilitates the creation of \"products\"
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: contact@weijbv.nl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface Healthy
 */
export interface Healthy {
    /**
     * 
     * @type {string}
     * @memberof Healthy
     */
    'service'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    'isAlive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    'isHealthy'?: boolean;
}
/**
 * 
 * @export
 * @interface PostProductModelSpecification
 */
export interface PostProductModelSpecification {
    /**
     * 
     * @type {number}
     * @memberof PostProductModelSpecification
     */
    'sequence': number;
    /**
     * 
     * @type {string}
     * @memberof PostProductModelSpecification
     */
    'sectionName': string;
    /**
     * 
     * @type {string}
     * @memberof PostProductModelSpecification
     */
    'formId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostProductModelSpecification
     */
    'visibleFor': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostProductModelSpecification
     */
    'mutableBy': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PostProductModelSpecification
     */
    'trackTime'?: boolean;
}
/**
 * 
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
    /**
     * 
     * @type {Array<PostProductModelSpecification>}
     * @memberof ProductModel
     */
    'specifications': Array<PostProductModelSpecification>;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    'version'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    'estProductionPeriod'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductModel
     */
    'visibleFor'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductModel
     */
    'mutableBy'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductModel
     */
    'trackTime'?: boolean;
    
}
/**
 * 
 * @export
 * @interface ProductSummary
 */
export interface ProductSummary {
    /**
     * 
     * @type {number}
     * @memberof ProductSummary
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSummary
     */
    'version'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSummary
     */
    'estProductionPeriod'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface PutProductModel
 */
export interface PutProductModel {
    /**
     * 
     * @type {Array<PutProductModelSpecification>}
     * @memberof PutProductModel
     */
    'specifications'?: Array<PutProductModelSpecification>;
    /**
     * 
     * @type {string}
     * @memberof PutProductModel
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof PutProductModel
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutProductModel
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PutProductModel
     */
    'estProductionPeriod'?: number;
    
}
/**
 * 
 * @export
 * @interface PutProductModelSpecification
 */
export interface PutProductModelSpecification {
    /**
     * 
     * @type {number}
     * @memberof PutProductModelSpecification
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutProductModelSpecification
     */
    'sequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutProductModelSpecification
     */
    'sectionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutProductModelSpecification
     */
    'formId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductModelSpecification
     */
    'visibleFor'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductModelSpecification
     */
    'mutableBy'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PutProductModelSpecification
     */
    'trackTime'?: boolean;
}

/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new product on the system
         * @summary Create new product
         * @param {ProductModel} [productModel] Full description of the product to create (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (productModel?: ProductModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete product
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProduct', 'id', id)
            const localVarPath = `/products/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves product details for the given product Id (within tenant context)
         * @summary Get product details
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductById', 'id', id)
            const localVarPath = `/products/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a list of products from the system\\n\\nReturns only the products that are available for the logged-in tenant
         * @summary Fetch product list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates given product with the new specification
         * @summary Update product specification
         * @param {string} id 
         * @param {PutProductModel} [putProductModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProductId: async (id: string, putProductModel?: PutProductModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putProductId', 'id', id)
            const localVarPath = `/products/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putProductModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new product on the system
         * @summary Create new product
         * @param {ProductModel} [productModel] Full description of the product to create (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(productModel?: ProductModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(productModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductApi.createProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary delete product
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductApi.deleteProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Healthy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductApi.getHealth']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves product details for the given product Id (within tenant context)
         * @summary Get product details
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductApi.getProductById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Fetch a list of products from the system\\n\\nReturns only the products that are available for the logged-in tenant
         * @summary Fetch product list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductApi.getProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Updates given product with the new specification
         * @summary Update product specification
         * @param {string} id 
         * @param {PutProductModel} [putProductModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProductId(id: string, putProductModel?: PutProductModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProductId(id, putProductModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductApi.putProductId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * Create a new product on the system
         * @summary Create new product
         * @param {ProductModel} [productModel] Full description of the product to create (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(productModel?: ProductModel, options?: any): AxiosPromise<void> {
            return localVarFp.createProduct(productModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete product
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<Healthy> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves product details for the given product Id (within tenant context)
         * @summary Get product details
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(id: number, options?: any): AxiosPromise<ProductModel> {
            return localVarFp.getProductById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of products from the system\\n\\nReturns only the products that are available for the logged-in tenant
         * @summary Fetch product list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(options?: any): AxiosPromise<Array<ProductSummary>> {
            return localVarFp.getProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates given product with the new specification
         * @summary Update product specification
         * @param {string} id 
         * @param {PutProductModel} [putProductModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProductId(id: string, putProductModel?: PutProductModel, options?: any): AxiosPromise<void> {
            return localVarFp.putProductId(id, putProductModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * Create a new product on the system
     * @summary Create new product
     * @param {ProductModel} [productModel] Full description of the product to create (including meta data)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public createProduct(productModel?: ProductModel, options?: RawAxiosRequestConfig) {
        return ProductApiFp(this.configuration).createProduct(productModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete product
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public deleteProduct(id: number, options?: RawAxiosRequestConfig) {
        return ProductApiFp(this.configuration).deleteProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Determine service health
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getHealth(options?: RawAxiosRequestConfig) {
        return ProductApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves product details for the given product Id (within tenant context)
     * @summary Get product details
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductById(id: number, options?: RawAxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a list of products from the system\\n\\nReturns only the products that are available for the logged-in tenant
     * @summary Fetch product list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProducts(options?: RawAxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates given product with the new specification
     * @summary Update product specification
     * @param {string} id 
     * @param {PutProductModel} [putProductModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public putProductId(id: string, putProductModel?: PutProductModel, options?: RawAxiosRequestConfig) {
        return ProductApiFp(this.configuration).putProductId(id, putProductModel, options).then((request) => request(this.axios, this.basePath));
    }
}



