/* tslint:disable */
/* eslint-disable */
/**
 * Scope Core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact: krizzje@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from "./base";

/**
 *
 * @export
 * @interface AppointmentDto
 */
export interface AppointmentDto {
  /**
   *
   * @type {number}
   * @memberof AppointmentDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AppointmentDto
   */
  appointmentType?: string | null;
  /**
   *
   * @type {string}
   * @memberof AppointmentDto
   */
  summary?: string | null;
  /**
   *
   * @type {string}
   * @memberof AppointmentDto
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof AppointmentDto
   */
  extraData?: string | null;
  /**
   *
   * @type {string}
   * @memberof AppointmentDto
   */
  startsAt?: string;
  /**
   *
   * @type {string}
   * @memberof AppointmentDto
   */
  endsAt?: string | null;
  /**
   *
   * @type {number}
   * @memberof AppointmentDto
   */
  orderId?: number | null;
}
/**
 *
 * @export
 * @interface BaseDeliveryDto
 */
export interface BaseDeliveryDto {
  /**
   *
   * @type {string}
   * @memberof BaseDeliveryDto
   */
  by?: string | null;
  /**
   *
   * @type {number}
   * @memberof BaseDeliveryDto
   */
  id?: number | null;
  /**
   *
   * @type {number}
   * @memberof BaseDeliveryDto
   */
  orderId?: number;
  /**
   *
   * @type {string}
   * @memberof BaseDeliveryDto
   */
  deliveryDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof BaseDeliveryDto
   */
  deliveryWindowPreference?: string | null;
  /**
   *
   * @type {string}
   * @memberof BaseDeliveryDto
   */
  extEventId?: string | null;
  /**
   *
   * @type {string}
   * @memberof BaseDeliveryDto
   */
  extCalendarId?: string | null;
  /**
   *
   * @type {number}
   * @memberof BaseDeliveryDto
   */
  price?: number | null;
  /**
   *
   * @type {string}
   * @memberof BaseDeliveryDto
   */
  internalComment?: string | null;
}
/**
 *
 * @export
 * @interface BaseProductDto
 */
export interface BaseProductDto {
  /**
   *
   * @type {string}
   * @memberof BaseProductDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof BaseProductDto
   */
  description?: string | null;
  /**
   *
   * @type {Array<ProductSpecification>}
   * @memberof BaseProductDto
   */
  specifications?: Array<ProductSpecification> | null;
}
/**
 *
 * @export
 * @interface BaseUserDto
 */
export interface BaseUserDto {
  /**
   *
   * @type {string}
   * @memberof BaseUserDto
   */
  userName?: string | null;
  /**
   *
   * @type {string}
   * @memberof BaseUserDto
   */
  email?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof BaseUserDto
   */
  enabled?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof BaseUserDto
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof BaseUserDto
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof BaseUserDto
   */
  dateCreated?: string | null;
  /**
   *
   * @type {string}
   * @memberof BaseUserDto
   */
  userrole?: string | null;
  /**
   *
   * @type {string}
   * @memberof BaseUserDto
   */
  role?: string | null;
  /**
   *
   * @type {number}
   * @memberof BaseUserDto
   */
  customerId?: number | null;
  /**
   *
   * @type {string}
   * @memberof BaseUserDto
   */
  confirmedStatus?: string | null;
}
/**
 *
 * @export
 * @interface BillOfMaterialAlterationDto
 */
export interface BillOfMaterialAlterationDto {
  /**
   *
   * @type {number}
   * @memberof BillOfMaterialAlterationDto
   */
  bomItemId?: number;
  /**
   *
   * @type {number}
   * @memberof BillOfMaterialAlterationDto
   */
  alternativeArticleId?: number;
  /**
   *
   * @type {string}
   * @memberof BillOfMaterialAlterationDto
   */
  alternativeArticleName?: string | null;
  /**
   *
   * @type {string}
   * @memberof BillOfMaterialAlterationDto
   */
  alternativeArticleDescription?: string | null;
  /**
   *
   * @type {number}
   * @memberof BillOfMaterialAlterationDto
   */
  unitsUsed?: number;
  /**
   *
   * @type {string}
   * @memberof BillOfMaterialAlterationDto
   */
  alterationRequestedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof BillOfMaterialAlterationDto
   */
  alterationRequestDate?: string;
}
/**
 *
 * @export
 * @interface BillOfMaterialDto
 */
export interface BillOfMaterialDto {
  /**
   *
   * @type {number}
   * @memberof BillOfMaterialDto
   */
  orderProductId?: number;
  /**
   *
   * @type {Array<BillOfMaterialItemDto>}
   * @memberof BillOfMaterialDto
   */
  items?: Array<BillOfMaterialItemDto> | null;
  /**
   *
   * @type {Array<BillOfMaterialAlterationDto>}
   * @memberof BillOfMaterialDto
   */
  alterations?: Array<BillOfMaterialAlterationDto> | null;
}
/**
 *
 * @export
 * @interface BillOfMaterialItemDto
 */
export interface BillOfMaterialItemDto {
  /**
   *
   * @type {number}
   * @memberof BillOfMaterialItemDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof BillOfMaterialItemDto
   */
  articleId?: number;
  /**
   *
   * @type {string}
   * @memberof BillOfMaterialItemDto
   */
  articleName?: string | null;
  /**
   *
   * @type {string}
   * @memberof BillOfMaterialItemDto
   */
  articleDescription?: string | null;
  /**
   *
   * @type {number}
   * @memberof BillOfMaterialItemDto
   */
  unitsNeeded?: number;
  /**
   *
   * @type {number}
   * @memberof BillOfMaterialItemDto
   */
  unitsAvailable?: number;
}
/**
 *
 * @export
 * @interface BillOfMaterialTemplateDto
 */
export interface BillOfMaterialTemplateDto {
  /**
   *
   * @type {number}
   * @memberof BillOfMaterialTemplateDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof BillOfMaterialTemplateDto
   */
  productId?: number;
  /**
   *
   * @type {string}
   * @memberof BillOfMaterialTemplateDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof BillOfMaterialTemplateDto
   */
  templateContent?: string | null;
}
/**
 *
 * @export
 * @interface ChangeLogItemDto
 */
export interface ChangeLogItemDto {
  /**
   *
   * @type {number}
   * @memberof ChangeLogItemDto
   */
  changedAt?: number;
  /**
   *
   * @type {string}
   * @memberof ChangeLogItemDto
   */
  changedBy?: string | null;
}
/**
 *
 * @export
 * @interface CopyOrderRequestDto
 */
export interface CopyOrderRequestDto {
  /**
   *
   * @type {number}
   * @memberof CopyOrderRequestDto
   */
  orderId: number;
  /**
   *
   * @type {number}
   * @memberof CopyOrderRequestDto
   */
  targetCustomerId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CopyOrderRequestDto
   */
  parts?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof CopyOrderRequestDto
   */
  newTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof CopyOrderRequestDto
   */
  newReference?: string | null;
}
/**
 *
 * @export
 * @interface CustomerAlternativeInvoiceAddress
 */
export interface CustomerAlternativeInvoiceAddress {
  /**
   *
   * @type {string}
   * @memberof CustomerAlternativeInvoiceAddress
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerAlternativeInvoiceAddress
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerAlternativeInvoiceAddress
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerAlternativeInvoiceAddress
   */
  phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerAlternativeInvoiceAddress
   */
  postalCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerAlternativeInvoiceAddress
   */
  number?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerAlternativeInvoiceAddress
   */
  numberSuffix?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerAlternativeInvoiceAddress
   */
  street?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerAlternativeInvoiceAddress
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerAlternativeInvoiceAddress
   */
  country?: string | null;
}
/**
 *
 * @export
 * @interface CustomerDetailsDto
 */
export interface CustomerDetailsDto {
  /**
   *
   * @type {number}
   * @memberof CustomerDetailsDto
   */
  id?: number;
  /**
   *
   * @type {CustomerTypeEnum}
   * @memberof CustomerDetailsDto
   */
  customerType?: CustomerTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  businessName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  city?: string | null;
  /**
   *
   * @type {number}
   * @memberof CustomerDetailsDto
   */
  lat?: number | null;
  /**
   *
   * @type {number}
   * @memberof CustomerDetailsDto
   */
  lon?: number | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  postalCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  number?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  numberSuffix?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  street?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  country?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CustomerDetailsDto
   */
  includeVAT?: boolean;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  vatNumber?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CustomerDetailsDto
   */
  reverseChargeVAT?: boolean;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  userName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  instagramUserName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerDetailsDto
   */
  extCustomerId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CustomerDetailsDto
   */
  alternativeInvoice?: boolean;
  /**
   *
   * @type {CustomerAlternativeInvoiceAddress}
   * @memberof CustomerDetailsDto
   */
  altInvoiceData?: CustomerAlternativeInvoiceAddress;
}

/**
 * @type CustomerGetCustomerList200ResponseInner
 * @export
 */
export type CustomerGetCustomerList200ResponseInner =
  | CustomerDetailsDto
  | CustomerSummaryDto;

/**
 *
 * @export
 * @interface CustomerSearchResultDto
 */
export interface CustomerSearchResultDto {
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultDto
   */
  resultDateTime?: string;
  /**
   *
   * @type {number}
   * @memberof CustomerSearchResultDto
   */
  customerId?: number;
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultDto
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultDto
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultDto
   */
  companyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultDto
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultDto
   */
  country?: string | null;
}
/**
 *
 * @export
 * @interface CustomerSummaryDto
 */
export interface CustomerSummaryDto {
  /**
   *
   * @type {number}
   * @memberof CustomerSummaryDto
   */
  id?: number;
  /**
   *
   * @type {CustomerTypeEnum}
   * @memberof CustomerSummaryDto
   */
  customerType?: CustomerTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CustomerSummaryDto
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerSummaryDto
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerSummaryDto
   */
  businessName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomerSummaryDto
   */
  city?: string | null;
  /**
   *
   * @type {number}
   * @memberof CustomerSummaryDto
   */
  lat?: number | null;
  /**
   *
   * @type {number}
   * @memberof CustomerSummaryDto
   */
  lon?: number | null;
}

/**
 *
 * @export
 * @enum {string}
 */

export const CustomerTypeEnum = {
  P: "P",
  C: "C",
  I: "I",
} as const;

export type CustomerTypeEnum =
  (typeof CustomerTypeEnum)[keyof typeof CustomerTypeEnum];

/**
 *
 * @export
 * @interface DeliveryDto
 */
export interface DeliveryDto {
  /**
   *
   * @type {string}
   * @memberof DeliveryDto
   */
  by?: string | null;
  /**
   *
   * @type {number}
   * @memberof DeliveryDto
   */
  id?: number | null;
  /**
   *
   * @type {number}
   * @memberof DeliveryDto
   */
  orderId?: number;
  /**
   *
   * @type {string}
   * @memberof DeliveryDto
   */
  deliveryDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeliveryDto
   */
  deliveryWindowPreference?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeliveryDto
   */
  extEventId?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeliveryDto
   */
  extCalendarId?: string | null;
  /**
   *
   * @type {number}
   * @memberof DeliveryDto
   */
  price?: number | null;
  /**
   *
   * @type {string}
   * @memberof DeliveryDto
   */
  internalComment?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeliveryDto
   */
  street?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeliveryDto
   */
  number?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeliveryDto
   */
  numberSuffix?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeliveryDto
   */
  postalcode?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeliveryDto
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeliveryDto
   */
  country?: string | null;
  /**
   *
   * @type {number}
   * @memberof DeliveryDto
   */
  lat?: number | null;
  /**
   *
   * @type {number}
   * @memberof DeliveryDto
   */
  lon?: number | null;
}
/**
 * @type DeliveryGetDelivery200Response
 * @export
 */
export type DeliveryGetDelivery200Response =
  | BaseDeliveryDto
  | DeliveryDto
  | ExternalDeliveryDto
  | PickupDeliveryDto;

/**
 *
 * @export
 * @interface EnvelopedSearchResultDto
 */
export interface EnvelopedSearchResultDto {
  /**
   *
   * @type {number}
   * @memberof EnvelopedSearchResultDto
   */
  hits?: number;
  /**
   *
   * @type {Array<EnvelopedSearchResultDtoResultsInner>}
   * @memberof EnvelopedSearchResultDto
   */
  results?: Array<EnvelopedSearchResultDtoResultsInner> | null;
}
/**
 * @type EnvelopedSearchResultDtoResultsInner
 * @export
 */
export type EnvelopedSearchResultDtoResultsInner =
  | CustomerSearchResultDto
  | ImageSearchResultDto
  | OrderSearchResultDto
  | SearchResultDto;

/**
 *
 * @export
 * @interface ExternalDeliveryDto
 */
export interface ExternalDeliveryDto {
  /**
   *
   * @type {string}
   * @memberof ExternalDeliveryDto
   */
  by?: string | null;
  /**
   *
   * @type {number}
   * @memberof ExternalDeliveryDto
   */
  id?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExternalDeliveryDto
   */
  orderId?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalDeliveryDto
   */
  deliveryDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExternalDeliveryDto
   */
  deliveryWindowPreference?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExternalDeliveryDto
   */
  extEventId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExternalDeliveryDto
   */
  extCalendarId?: string | null;
  /**
   *
   * @type {number}
   * @memberof ExternalDeliveryDto
   */
  price?: number | null;
  /**
   *
   * @type {string}
   * @memberof ExternalDeliveryDto
   */
  internalComment?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExternalDeliveryDto
   */
  street?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExternalDeliveryDto
   */
  number?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExternalDeliveryDto
   */
  numberSuffix?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExternalDeliveryDto
   */
  postalcode?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExternalDeliveryDto
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExternalDeliveryDto
   */
  country?: string | null;
  /**
   *
   * @type {number}
   * @memberof ExternalDeliveryDto
   */
  lat?: number | null;
  /**
   *
   * @type {number}
   * @memberof ExternalDeliveryDto
   */
  lon?: number | null;
  /**
   *
   * @type {string}
   * @memberof ExternalDeliveryDto
   */
  transporter?: string | null;
}
/**
 *
 * @export
 * @interface FormDetailsDto
 */
export interface FormDetailsDto {
  /**
   *
   * @type {string}
   * @memberof FormDetailsDto
   */
  id?: string | null;
  /**
   *
   * @type {Array<FormDetailsDtoFormSpecInner>}
   * @memberof FormDetailsDto
   */
  formSpec?: Array<FormDetailsDtoFormSpecInner> | null;
  /**
   *
   * @type {string}
   * @memberof FormDetailsDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormDetailsDto
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof FormDetailsDto
   */
  version?: number | null;
  /**
   *
   * @type {number}
   * @memberof FormDetailsDto
   */
  schemaVersion?: number | null;
}
/**
 * @type FormDetailsDtoFormSpecInner
 * @export
 */
export type FormDetailsDtoFormSpecInner =
  | FormElementCheckboxesDto
  | FormElementDatePickerDto
  | FormElementDto
  | FormElementHeadingDto
  | FormElementImageUploadDto
  | FormElementInputCurrencyDto
  | FormElementInputNumberDto
  | FormElementInputTextDto
  | FormElementParagraphDto
  | FormElementRadioButtonsDto
  | FormElementRangeDto
  | FormElementRatingDto
  | FormElementSelectDto
  | FormElementSignatureDto
  | FormElementTextAreaDto
  | FormElementTimePickerDto
  | FormElementWithOptionsDto;

/**
 *
 * @export
 * @interface FormElementCheckboxesDto
 */
export interface FormElementCheckboxesDto {
  /**
   *
   * @type {string}
   * @memberof FormElementCheckboxesDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementCheckboxesDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementCheckboxesDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementCheckboxesDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementCheckboxesDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementCheckboxesDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementCheckboxesDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementCheckboxesDto
   */
  validation?: FormElementValidationAttributes;
  /**
   *
   * @type {Array<InputOptionDto>}
   * @memberof FormElementCheckboxesDto
   */
  options?: Array<InputOptionDto> | null;
}
/**
 *
 * @export
 * @interface FormElementDatePickerDto
 */
export interface FormElementDatePickerDto {
  /**
   *
   * @type {string}
   * @memberof FormElementDatePickerDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementDatePickerDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementDatePickerDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementDatePickerDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementDatePickerDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementDatePickerDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementDatePickerDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementDatePickerDto
   */
  validation?: FormElementValidationAttributes;
}
/**
 *
 * @export
 * @interface FormElementDto
 */
export interface FormElementDto {
  /**
   *
   * @type {string}
   * @memberof FormElementDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementDto
   */
  validation?: FormElementValidationAttributes;
}
/**
 *
 * @export
 * @interface FormElementHeadingDto
 */
export interface FormElementHeadingDto {
  /**
   *
   * @type {string}
   * @memberof FormElementHeadingDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementHeadingDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementHeadingDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementHeadingDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementHeadingDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementHeadingDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementHeadingDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementHeadingDto
   */
  validation?: FormElementValidationAttributes;
  /**
   *
   * @type {number}
   * @memberof FormElementHeadingDto
   */
  headerSize?: number | null;
}
/**
 *
 * @export
 * @interface FormElementImageUploadDto
 */
export interface FormElementImageUploadDto {
  /**
   *
   * @type {string}
   * @memberof FormElementImageUploadDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementImageUploadDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementImageUploadDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementImageUploadDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementImageUploadDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementImageUploadDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementImageUploadDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementImageUploadDto
   */
  validation?: FormElementValidationAttributes;
}
/**
 *
 * @export
 * @interface FormElementIndexingAttributes
 */
export interface FormElementIndexingAttributes {
  /**
   *
   * @type {string}
   * @memberof FormElementIndexingAttributes
   */
  indexKey?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof FormElementIndexingAttributes
   */
  shouldIndex?: boolean;
}
/**
 *
 * @export
 * @interface FormElementInputCurrencyDto
 */
export interface FormElementInputCurrencyDto {
  /**
   *
   * @type {string}
   * @memberof FormElementInputCurrencyDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementInputCurrencyDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementInputCurrencyDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementInputCurrencyDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementInputCurrencyDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementInputCurrencyDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementInputCurrencyDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementInputCurrencyDto
   */
  validation?: FormElementValidationAttributes;
  /**
   *
   * @type {boolean}
   * @memberof FormElementInputCurrencyDto
   */
  showCurrencySymbol?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof FormElementInputCurrencyDto
   */
  currencyCode?: string | null;
  /**
   *
   * @type {number}
   * @memberof FormElementInputCurrencyDto
   */
  min?: number | null;
  /**
   *
   * @type {number}
   * @memberof FormElementInputCurrencyDto
   */
  max?: number | null;
}
/**
 *
 * @export
 * @interface FormElementInputNumberDto
 */
export interface FormElementInputNumberDto {
  /**
   *
   * @type {string}
   * @memberof FormElementInputNumberDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementInputNumberDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementInputNumberDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementInputNumberDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementInputNumberDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementInputNumberDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementInputNumberDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementInputNumberDto
   */
  validation?: FormElementValidationAttributes;
  /**
   *
   * @type {number}
   * @memberof FormElementInputNumberDto
   */
  numDecimals?: number | null;
  /**
   *
   * @type {number}
   * @memberof FormElementInputNumberDto
   */
  min?: number | null;
  /**
   *
   * @type {number}
   * @memberof FormElementInputNumberDto
   */
  max?: number | null;
  /**
   *
   * @type {number}
   * @memberof FormElementInputNumberDto
   */
  step?: number | null;
}
/**
 *
 * @export
 * @interface FormElementInputTextDto
 */
export interface FormElementInputTextDto {
  /**
   *
   * @type {string}
   * @memberof FormElementInputTextDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementInputTextDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementInputTextDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementInputTextDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementInputTextDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementInputTextDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementInputTextDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementInputTextDto
   */
  validation?: FormElementValidationAttributes;
}
/**
 *
 * @export
 * @interface FormElementParagraphDto
 */
export interface FormElementParagraphDto {
  /**
   *
   * @type {string}
   * @memberof FormElementParagraphDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementParagraphDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementParagraphDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementParagraphDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementParagraphDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementParagraphDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementParagraphDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementParagraphDto
   */
  validation?: FormElementValidationAttributes;
}
/**
 *
 * @export
 * @interface FormElementRadioButtonsDto
 */
export interface FormElementRadioButtonsDto {
  /**
   *
   * @type {string}
   * @memberof FormElementRadioButtonsDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementRadioButtonsDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementRadioButtonsDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementRadioButtonsDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementRadioButtonsDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementRadioButtonsDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementRadioButtonsDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementRadioButtonsDto
   */
  validation?: FormElementValidationAttributes;
  /**
   *
   * @type {Array<InputOptionDto>}
   * @memberof FormElementRadioButtonsDto
   */
  options?: Array<InputOptionDto> | null;
}
/**
 *
 * @export
 * @interface FormElementRangeDto
 */
export interface FormElementRangeDto {
  /**
   *
   * @type {string}
   * @memberof FormElementRangeDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementRangeDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementRangeDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementRangeDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementRangeDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementRangeDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementRangeDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementRangeDto
   */
  validation?: FormElementValidationAttributes;
  /**
   *
   * @type {InputRangeAttributesDto}
   * @memberof FormElementRangeDto
   */
  rangeSettings?: InputRangeAttributesDto;
}
/**
 *
 * @export
 * @interface FormElementRatingDto
 */
export interface FormElementRatingDto {
  /**
   *
   * @type {string}
   * @memberof FormElementRatingDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementRatingDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementRatingDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementRatingDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementRatingDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementRatingDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementRatingDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementRatingDto
   */
  validation?: FormElementValidationAttributes;
}
/**
 *
 * @export
 * @interface FormElementSelectDto
 */
export interface FormElementSelectDto {
  /**
   *
   * @type {string}
   * @memberof FormElementSelectDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementSelectDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementSelectDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementSelectDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementSelectDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementSelectDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementSelectDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementSelectDto
   */
  validation?: FormElementValidationAttributes;
  /**
   *
   * @type {Array<InputOptionDto>}
   * @memberof FormElementSelectDto
   */
  options?: Array<InputOptionDto> | null;
}
/**
 *
 * @export
 * @interface FormElementSignatureDto
 */
export interface FormElementSignatureDto {
  /**
   *
   * @type {string}
   * @memberof FormElementSignatureDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementSignatureDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementSignatureDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementSignatureDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementSignatureDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementSignatureDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementSignatureDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementSignatureDto
   */
  validation?: FormElementValidationAttributes;
}
/**
 *
 * @export
 * @interface FormElementTextAreaDto
 */
export interface FormElementTextAreaDto {
  /**
   *
   * @type {string}
   * @memberof FormElementTextAreaDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementTextAreaDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementTextAreaDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementTextAreaDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementTextAreaDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementTextAreaDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementTextAreaDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementTextAreaDto
   */
  validation?: FormElementValidationAttributes;
  /**
   *
   * @type {boolean}
   * @memberof FormElementTextAreaDto
   */
  autoSize?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof FormElementTextAreaDto
   */
  cols?: number | null;
  /**
   *
   * @type {number}
   * @memberof FormElementTextAreaDto
   */
  rows?: number | null;
}
/**
 *
 * @export
 * @interface FormElementTimePickerDto
 */
export interface FormElementTimePickerDto {
  /**
   *
   * @type {string}
   * @memberof FormElementTimePickerDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementTimePickerDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementTimePickerDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementTimePickerDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementTimePickerDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementTimePickerDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementTimePickerDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementTimePickerDto
   */
  validation?: FormElementValidationAttributes;
  /**
   *
   * @type {boolean}
   * @memberof FormElementTimePickerDto
   */
  show24hrs?: boolean | null;
}
/**
 *
 * @export
 * @interface FormElementValidationAttributes
 */
export interface FormElementValidationAttributes {
  /**
   *
   * @type {string}
   * @memberof FormElementValidationAttributes
   */
  type?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof FormElementValidationAttributes
   */
  isRequired?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementValidationAttributes
   */
  requiredFor?: Array<string> | null;
}
/**
 *
 * @export
 * @interface FormElementWithOptionsDto
 */
export interface FormElementWithOptionsDto {
  /**
   *
   * @type {string}
   * @memberof FormElementWithOptionsDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementWithOptionsDto
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormElementWithOptionsDto
   */
  label?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementWithOptionsDto
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof FormElementWithOptionsDto
   */
  value?: any | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FormElementWithOptionsDto
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {FormElementIndexingAttributes}
   * @memberof FormElementWithOptionsDto
   */
  indexing?: FormElementIndexingAttributes;
  /**
   *
   * @type {FormElementValidationAttributes}
   * @memberof FormElementWithOptionsDto
   */
  validation?: FormElementValidationAttributes;
  /**
   *
   * @type {Array<InputOptionDto>}
   * @memberof FormElementWithOptionsDto
   */
  options?: Array<InputOptionDto> | null;
}
/**
 *
 * @export
 * @interface FormSummaryDto
 */
export interface FormSummaryDto {
  /**
   *
   * @type {string}
   * @memberof FormSummaryDto
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormSummaryDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof FormSummaryDto
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof FormSummaryDto
   */
  version?: number | null;
}
/**
 *
 * @export
 * @interface ImageSearchResultDto
 */
export interface ImageSearchResultDto {
  /**
   *
   * @type {string}
   * @memberof ImageSearchResultDto
   */
  resultDateTime?: string;
  /**
   *
   * @type {string}
   * @memberof ImageSearchResultDto
   */
  id?: string | null;
  /**
   *
   * @type {number}
   * @memberof ImageSearchResultDto
   */
  orderId?: number | null;
  /**
   *
   * @type {string}
   * @memberof ImageSearchResultDto
   */
  mimeType?: string | null;
  /**
   *
   * @type {string}
   * @memberof ImageSearchResultDto
   */
  originalFilename?: string | null;
  /**
   *
   * @type {string}
   * @memberof ImageSearchResultDto
   */
  fileName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ImageSearchResultDto
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ImageSearchResultDto
   */
  formId?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ImageSearchResultDto
   */
  tags?: Array<string> | null;
}
/**
 *
 * @export
 * @interface InputOptionDto
 */
export interface InputOptionDto {
  /**
   *
   * @type {string}
   * @memberof InputOptionDto
   */
  text?: string | null;
  /**
   *
   * @type {string}
   * @memberof InputOptionDto
   */
  value?: string | null;
}
/**
 *
 * @export
 * @interface InputRangeAttributesDto
 */
export interface InputRangeAttributesDto {
  /**
   *
   * @type {number}
   * @memberof InputRangeAttributesDto
   */
  min?: number;
  /**
   *
   * @type {number}
   * @memberof InputRangeAttributesDto
   */
  max?: number;
  /**
   *
   * @type {number}
   * @memberof InputRangeAttributesDto
   */
  step?: number;
}
/**
 *
 * @export
 * @interface MessageDto
 */
export interface MessageDto {
  /**
   *
   * @type {number}
   * @memberof MessageDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  recipientUserName?: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  recipientRole?: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  subject?: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  message?: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  updatedAt?: string | null;
}
/**
 *
 * @export
 * @interface MutationResult
 */
export interface MutationResult {
  /**
   *
   * @type {boolean}
   * @memberof MutationResult
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof MutationResult
   */
  message?: string | null;
}
/**
 *
 * @export
 * @interface NewTenantBodyDto
 */
export interface NewTenantBodyDto {
  /**
   *
   * @type {string}
   * @memberof NewTenantBodyDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof NewTenantBodyDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof NewTenantBodyDto
   */
  tier: string;
  /**
   *
   * @type {boolean}
   * @memberof NewTenantBodyDto
   */
  active?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof NewTenantBodyDto
   */
  companyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof NewTenantBodyDto
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof NewTenantBodyDto
   */
  contactEmail: string;
  /**
   *
   * @type {string}
   * @memberof NewTenantBodyDto
   */
  contactFirstName: string;
  /**
   *
   * @type {string}
   * @memberof NewTenantBodyDto
   */
  contactLastName: string;
  /**
   *
   * @type {string}
   * @memberof NewTenantBodyDto
   */
  contactPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof NewTenantBodyDto
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof NewTenantBodyDto
   */
  userName?: string | null;
  /**
   *
   * @type {string}
   * @memberof NewTenantBodyDto
   */
  debtorId?: string | null;
  /**
   *
   * @type {string}
   * @memberof NewTenantBodyDto
   */
  address?: string | null;
}
/**
 *
 * @export
 * @interface OrderCreateDto
 */
export interface OrderCreateDto {
  /**
   *
   * @type {OrderSummaryDto}
   * @memberof OrderCreateDto
   */
  summary?: OrderSummaryDto;
  /**
   *
   * @type {OrderDetailsDto}
   * @memberof OrderCreateDto
   */
  details?: OrderDetailsDto;
}
/**
 *
 * @export
 * @interface OrderDetailsDto
 */
export interface OrderDetailsDto {
  /**
   *
   * @type {Array<ChangeLogItemDto>}
   * @memberof OrderDetailsDto
   */
  changelog?: Array<ChangeLogItemDto> | null;
  /**
   *
   * @type {{ [key: string]: { [key: string]: any; }; }}
   * @memberof OrderDetailsDto
   */
  details?: { [key: string]: { [key: string]: any } } | null;
  /**
   *
   * @type {{ [key: string]: Array<FormDetailsDtoFormSpecInner>; }}
   * @memberof OrderDetailsDto
   */
  formSpecification?: {
    [key: string]: Array<FormDetailsDtoFormSpecInner>;
  } | null;
  /**
   *
   * @type {{ [key: string]: ProductDto; }}
   * @memberof OrderDetailsDto
   */
  productSpecification?: { [key: string]: ProductDto } | null;
  /**
   *
   * @type {Array<OrderProductDto>}
   * @memberof OrderDetailsDto
   */
  orderSpecification?: Array<OrderProductDto> | null;
}
/**
 * @type OrderGetInProgressOrderData200ResponseInner
 * @export
 */
export type OrderGetInProgressOrderData200ResponseInner =
  | OrdersInProgressRecordDto
  | OrdersWorkedOnFromRecordDto;

/**
 *
 * @export
 * @interface OrderProductDto
 */
export interface OrderProductDto {
  /**
   *
   * @type {number}
   * @memberof OrderProductDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof OrderProductDto
   */
  orderId?: number;
  /**
   *
   * @type {number}
   * @memberof OrderProductDto
   */
  productId?: number;
  /**
   *
   * @type {string}
   * @memberof OrderProductDto
   */
  extSalesOrderLineId?: string | null;
  /**
   *
   * @type {number}
   * @memberof OrderProductDto
   */
  sequence?: number;
  /**
   *
   * @type {string}
   * @memberof OrderProductDto
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof OrderProductDto
   */
  total?: number;
  /**
   *
   * @type {number}
   * @memberof OrderProductDto
   */
  worktimeEstimation?: number;
  /**
   *
   * @type {number}
   * @memberof OrderProductDto
   */
  costEstimation?: number;
}
/**
 *
 * @export
 * @interface OrderProgressSummaryDto
 */
export interface OrderProgressSummaryDto {
  /**
   *
   * @type {string}
   * @memberof OrderProgressSummaryDto
   */
  formId?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderProgressSummaryDto
   */
  formName?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderProgressSummaryDto
   */
  approvalState?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderProgressSummaryDto
   */
  progressState?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderProgressSummaryDto
   */
  progressChangedBy?: string | null;
}
/**
 *
 * @export
 * @interface OrderSearchResultDto
 */
export interface OrderSearchResultDto {
  /**
   *
   * @type {string}
   * @memberof OrderSearchResultDto
   */
  resultDateTime?: string;
  /**
   *
   * @type {number}
   * @memberof OrderSearchResultDto
   */
  orderId?: number;
  /**
   *
   * @type {string}
   * @memberof OrderSearchResultDto
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof OrderSearchResultDto
   */
  customerId?: number | null;
  /**
   *
   * @type {string}
   * @memberof OrderSearchResultDto
   */
  reference?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const OrderStateEnum = {
  Planned: "Planned",
  NearDeadline: "NearDeadline",
  PastDeadline: "PastDeadline",
  Aftercare: "Aftercare",
  Done: "Done",
  OnHold: "OnHold",
  Cancelled: "Cancelled",
  PendingPayment: "PendingPayment",
  ReadyForTransport: "ReadyForTransport",
} as const;

export type OrderStateEnum =
  (typeof OrderStateEnum)[keyof typeof OrderStateEnum];

/**
 *
 * @export
 * @interface OrderStateTallyAssignedDto
 */
export interface OrderStateTallyAssignedDto {
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyAssignedDto
   */
  open?: number;
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyAssignedDto
   */
  other?: number;
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyAssignedDto
   */
  total?: number;
}
/**
 *
 * @export
 * @interface OrderStateTallyDto
 */
export interface OrderStateTallyDto {
  /**
   *
   * @type {OrderStateTallyOpenDto}
   * @memberof OrderStateTallyDto
   */
  open?: OrderStateTallyOpenDto;
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyDto
   */
  done?: number;
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyDto
   */
  onHold?: number;
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyDto
   */
  pendingPayment?: number;
  /**
   *
   * @type {OrderStateTallyAssignedDto}
   * @memberof OrderStateTallyDto
   */
  assigned?: OrderStateTallyAssignedDto;
}
/**
 *
 * @export
 * @interface OrderStateTallyOpenDto
 */
export interface OrderStateTallyOpenDto {
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyOpenDto
   */
  total?: number;
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyOpenDto
   */
  planned?: number;
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyOpenDto
   */
  nearDeadline?: number;
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyOpenDto
   */
  pastDeadline?: number;
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyOpenDto
   */
  aftercare?: number;
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyOpenDto
   */
  delivery?: number;
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyOpenDto
   */
  readyForTransport?: number;
  /**
   *
   * @type {number}
   * @memberof OrderStateTallyOpenDto
   */
  totalWorkTimeEstimation?: number;
}
/**
 *
 * @export
 * @interface OrderSummaryDto
 */
export interface OrderSummaryDto {
  /**
   *
   * @type {number}
   * @memberof OrderSummaryDto
   */
  id?: number | null;
  /**
   *
   * @type {string}
   * @memberof OrderSummaryDto
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderSummaryDto
   */
  reference?: string | null;
  /**
   *
   * @type {OrderStateEnum}
   * @memberof OrderSummaryDto
   */
  state?: OrderStateEnum;
  /**
   *
   * @type {string}
   * @memberof OrderSummaryDto
   */
  deadline?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderSummaryDto
   */
  creator?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderSummaryDto
   */
  dateCreated?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderSummaryDto
   */
  dateLastModified?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof OrderSummaryDto
   */
  assignedTo?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof OrderSummaryDto
   */
  workedOnBy?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof OrderSummaryDto
   */
  customerId?: number | null;
  /**
   *
   * @type {number}
   * @memberof OrderSummaryDto
   */
  orderTotal?: number | null;
  /**
   *
   * @type {number}
   * @memberof OrderSummaryDto
   */
  paid?: number | null;
  /**
   *
   * @type {number}
   * @memberof OrderSummaryDto
   */
  hourlyRate?: number | null;
  /**
   *
   * @type {string}
   * @memberof OrderSummaryDto
   */
  extSalesOrderId?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderSummaryDto
   */
  extDocumentId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof OrderSummaryDto
   */
  invoiced?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof OrderSummaryDto
   */
  invoiceNumber?: number | null;
  /**
   *
   * @type {string}
   * @memberof OrderSummaryDto
   */
  reviewRequestSendDate?: string | null;
  /**
   *
   * @type {Array<OrderProgressSummaryDto>}
   * @memberof OrderSummaryDto
   */
  progressData?: Array<OrderProgressSummaryDto> | null;
}

/**
 *
 * @export
 * @enum {string}
 */

export const OrderTypeFilterEnum = {
  Planned: "Planned",
  OnHold: "OnHold",
  Delivery: "Delivery",
  PendingPayment: "PendingPayment",
  ReadyForTransport: "ReadyForTransport",
  Assigned: "Assigned",
  Done: "Done",
} as const;

export type OrderTypeFilterEnum =
  (typeof OrderTypeFilterEnum)[keyof typeof OrderTypeFilterEnum];

/**
 *
 * @export
 * @interface OrdersInProgressRecordDto
 */
export interface OrdersInProgressRecordDto {
  /**
   *
   * @type {number}
   * @memberof OrdersInProgressRecordDto
   */
  orderId?: number;
  /**
   *
   * @type {number}
   * @memberof OrdersInProgressRecordDto
   */
  customerId?: number;
  /**
   *
   * @type {string}
   * @memberof OrdersInProgressRecordDto
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrdersInProgressRecordDto
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrdersInProgressRecordDto
   */
  sectionName?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrdersInProgressRecordDto
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof OrdersInProgressRecordDto
   */
  start?: number;
}
/**
 *
 * @export
 * @interface OrdersWorkedOnFromRecordDto
 */
export interface OrdersWorkedOnFromRecordDto {
  /**
   *
   * @type {number}
   * @memberof OrdersWorkedOnFromRecordDto
   */
  orderId?: number;
  /**
   *
   * @type {number}
   * @memberof OrdersWorkedOnFromRecordDto
   */
  customerId?: number;
  /**
   *
   * @type {string}
   * @memberof OrdersWorkedOnFromRecordDto
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrdersWorkedOnFromRecordDto
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrdersWorkedOnFromRecordDto
   */
  sectionName?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrdersWorkedOnFromRecordDto
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof OrdersWorkedOnFromRecordDto
   */
  start?: number;
  /**
   *
   * @type {boolean}
   * @memberof OrdersWorkedOnFromRecordDto
   */
  activeNow?: boolean;
  /**
   *
   * @type {number}
   * @memberof OrdersWorkedOnFromRecordDto
   */
  timeSpentNow?: number;
  /**
   *
   * @type {number}
   * @memberof OrdersWorkedOnFromRecordDto
   */
  timeSpentTotal?: number;
}
/**
 *
 * @export
 * @interface PickupDeliveryDto
 */
export interface PickupDeliveryDto {
  /**
   *
   * @type {string}
   * @memberof PickupDeliveryDto
   */
  by?: string | null;
  /**
   *
   * @type {number}
   * @memberof PickupDeliveryDto
   */
  id?: number | null;
  /**
   *
   * @type {number}
   * @memberof PickupDeliveryDto
   */
  orderId?: number;
  /**
   *
   * @type {string}
   * @memberof PickupDeliveryDto
   */
  deliveryDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof PickupDeliveryDto
   */
  deliveryWindowPreference?: string | null;
  /**
   *
   * @type {string}
   * @memberof PickupDeliveryDto
   */
  extEventId?: string | null;
  /**
   *
   * @type {string}
   * @memberof PickupDeliveryDto
   */
  extCalendarId?: string | null;
  /**
   *
   * @type {number}
   * @memberof PickupDeliveryDto
   */
  price?: number | null;
  /**
   *
   * @type {string}
   * @memberof PickupDeliveryDto
   */
  internalComment?: string | null;
  /**
   *
   * @type {string}
   * @memberof PickupDeliveryDto
   */
  location?: string | null;
}
/**
 * @type ProductCreateProductRequest
 * @export
 */
export type ProductCreateProductRequest = BaseProductDto | ProductDto;

/**
 *
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
  /**
   *
   * @type {string}
   * @memberof ProductDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductDto
   */
  description?: string | null;
  /**
   *
   * @type {Array<ProductSpecification>}
   * @memberof ProductDto
   */
  specifications?: Array<ProductSpecification> | null;
  /**
   *
   * @type {number}
   * @memberof ProductDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ProductDto
   */
  version?: number;
}
/**
 *
 * @export
 * @interface ProductSpecification
 */
export interface ProductSpecification {
  /**
   *
   * @type {number}
   * @memberof ProductSpecification
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ProductSpecification
   */
  sectionName?: string | null;
  /**
   *
   * @type {number}
   * @memberof ProductSpecification
   */
  sequence?: number;
  /**
   *
   * @type {string}
   * @memberof ProductSpecification
   */
  formId?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ProductSpecification
   */
  visibleFor?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ProductSpecification
   */
  mutableBy?: Array<string> | null;
  /**
   *
   * @type {boolean}
   * @memberof ProductSpecification
   */
  trackTime?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductSpecification
   */
  createdAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductSpecification
   */
  lastUpdatedAt?: string | null;
}
/**
 *
 * @export
 * @interface SearchResultDto
 */
export interface SearchResultDto {
  /**
   *
   * @type {string}
   * @memberof SearchResultDto
   */
  resultDateTime?: string;
}
/**
 *
 * @export
 * @interface ShoppingListItemDto
 */
export interface ShoppingListItemDto {
  /**
   *
   * @type {number}
   * @memberof ShoppingListItemDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ShoppingListItemDto
   */
  numberNeeded?: number;
  /**
   *
   * @type {number}
   * @memberof ShoppingListItemDto
   */
  numberActual?: number;
  /**
   *
   * @type {string}
   * @memberof ShoppingListItemDto
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShoppingListItemDto
   */
  location?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShoppingListItemDto
   */
  status?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShoppingListItemDto
   */
  requester?: string | null;
  /**
   *
   * @type {number}
   * @memberof ShoppingListItemDto
   */
  orderId?: number | null;
  /**
   *
   * @type {string}
   * @memberof ShoppingListItemDto
   */
  requestDate?: string;
  /**
   *
   * @type {string}
   * @memberof ShoppingListItemDto
   */
  deadline?: string | null;
}
/**
 *
 * @export
 * @interface StockArticleDto
 */
export interface StockArticleDto {
  /**
   *
   * @type {number}
   * @memberof StockArticleDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof StockArticleDto
   */
  categoryId?: number;
  /**
   *
   * @type {string}
   * @memberof StockArticleDto
   */
  brandName?: string | null;
  /**
   *
   * @type {string}
   * @memberof StockArticleDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof StockArticleDto
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof StockArticleDto
   */
  unit?: string | null;
  /**
   *
   * @type {string}
   * @memberof StockArticleDto
   */
  articleNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof StockArticleDto
   */
  barcode?: string | null;
  /**
   *
   * @type {number}
   * @memberof StockArticleDto
   */
  availableUnits?: number | null;
  /**
   *
   * @type {number}
   * @memberof StockArticleDto
   */
  procurementCost?: number | null;
  /**
   *
   * @type {number}
   * @memberof StockArticleDto
   */
  maxStockAmount?: number | null;
  /**
   *
   * @type {number}
   * @memberof StockArticleDto
   */
  safetyStockAmount?: number | null;
  /**
   *
   * @type {number}
   * @memberof StockArticleDto
   */
  reorderPoint?: number | null;
  /**
   *
   * @type {string}
   * @memberof StockArticleDto
   */
  intrastatCode?: string | null;
  /**
   *
   * @type {number}
   * @memberof StockArticleDto
   */
  grossWeight?: number | null;
  /**
   *
   * @type {number}
   * @memberof StockArticleDto
   */
  netWeight?: number | null;
  /**
   *
   * @type {string}
   * @memberof StockArticleDto
   */
  countryOfOrigin?: string | null;
}
/**
 *
 * @export
 * @interface StockArticleMutationDto
 */
export interface StockArticleMutationDto {
  /**
   *
   * @type {number}
   * @memberof StockArticleMutationDto
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof StockArticleMutationDto
   */
  locationId?: number | null;
  /**
   *
   * @type {number}
   * @memberof StockArticleMutationDto
   */
  articleId?: number;
}
/**
 *
 * @export
 * @interface StockArticleSummaryDto
 */
export interface StockArticleSummaryDto {
  /**
   *
   * @type {number}
   * @memberof StockArticleSummaryDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof StockArticleSummaryDto
   */
  categoryId?: number;
  /**
   *
   * @type {string}
   * @memberof StockArticleSummaryDto
   */
  brandName?: string | null;
  /**
   *
   * @type {string}
   * @memberof StockArticleSummaryDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof StockArticleSummaryDto
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof StockArticleSummaryDto
   */
  unit?: string | null;
  /**
   *
   * @type {string}
   * @memberof StockArticleSummaryDto
   */
  articleNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof StockArticleSummaryDto
   */
  barcode?: string | null;
}
/**
 *
 * @export
 * @interface StockCategoryDto
 */
export interface StockCategoryDto {
  /**
   *
   * @type {number}
   * @memberof StockCategoryDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof StockCategoryDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof StockCategoryDto
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof StockCategoryDto
   */
  parentCategoryId?: number | null;
}
/**
 * @type StockManagementGetArticleLocations200ResponseInner
 * @export
 */
export type StockManagementGetArticleLocations200ResponseInner =
  | StockArticleMutationDto
  | StockMutationBaseDto
  | StockMutationDto;

/**
 * @type StockManagementGetArticles200ResponseInner
 * @export
 */
export type StockManagementGetArticles200ResponseInner =
  | StockArticleDto
  | StockArticleSummaryDto
  | object;

/**
 *
 * @export
 * @interface StockMutationBaseDto
 */
export interface StockMutationBaseDto {
  /**
   *
   * @type {number}
   * @memberof StockMutationBaseDto
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof StockMutationBaseDto
   */
  locationId?: number | null;
}
/**
 *
 * @export
 * @interface StockMutationDto
 */
export interface StockMutationDto {
  /**
   *
   * @type {number}
   * @memberof StockMutationDto
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof StockMutationDto
   */
  locationId?: number | null;
  /**
   *
   * @type {number}
   * @memberof StockMutationDto
   */
  articleId?: number;
  /**
   *
   * @type {number}
   * @memberof StockMutationDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof StockMutationDto
   */
  mutationDate?: string;
  /**
   *
   * @type {string}
   * @memberof StockMutationDto
   */
  mutatedBy?: string | null;
}
/**
 *
 * @export
 * @interface StockStorageLocationDto
 */
export interface StockStorageLocationDto {
  /**
   *
   * @type {number}
   * @memberof StockStorageLocationDto
   */
  id?: number | null;
  /**
   *
   * @type {number}
   * @memberof StockStorageLocationDto
   */
  warehouseId?: number;
  /**
   *
   * @type {string}
   * @memberof StockStorageLocationDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof StockStorageLocationDto
   */
  locationType?: string | null;
}
/**
 *
 * @export
 * @interface StockWarehouseDto
 */
export interface StockWarehouseDto {
  /**
   *
   * @type {number}
   * @memberof StockWarehouseDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof StockWarehouseDto
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof StockWarehouseDto
   */
  description?: string | null;
}
/**
 *
 * @export
 * @interface TenantFullDetailsDto
 */
export interface TenantFullDetailsDto {
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  tier: string;
  /**
   *
   * @type {boolean}
   * @memberof TenantFullDetailsDto
   */
  active?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  identityPoolId: string;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  userPoolId: string;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  systemAdminPolicy?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  systemAdminRole?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  systemSupportPolicy?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  systemSupportRole?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  trustRole?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  companyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  contactEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  contactFirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  contactLastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  contactPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  userName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  debtorId?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantFullDetailsDto
   */
  address?: string | null;
}
/**
 * @type TenantGetTenantSystemDetailsById200Response
 * @export
 */
export type TenantGetTenantSystemDetailsById200Response =
  | TenantFullDetailsDto
  | TenantSystemSummaryDto;

/**
 * @type TenantGetTenants200ResponseInner
 * @export
 */
export type TenantGetTenants200ResponseInner =
  | NewTenantBodyDto
  | TenantFullDetailsDto
  | TenantSummaryDto
  | TenantSystemSummaryDto
  | TenantUpdateBodyDto;

/**
 * DTO for tenant settings
 * @export
 * @interface TenantSettingDto
 */
export interface TenantSettingDto {
  /**
   * Integer ID used as setting identifier
   * @type {number}
   * @memberof TenantSettingDto
   */
  id?: number | null;
  /**
   *
   * @type {string}
   * @memberof TenantSettingDto
   */
  subsystem: string;
  /**
   *
   * @type {string}
   * @memberof TenantSettingDto
   */
  settingName: string;
  /**
   *
   * @type {any}
   * @memberof TenantSettingDto
   */
  settingValue?: any | null;
  /**
   *
   * @type {boolean}
   * @memberof TenantSettingDto
   */
  sensitive: boolean;
}
/**
 *
 * @export
 * @interface TenantSummaryDto
 */
export interface TenantSummaryDto {
  /**
   *
   * @type {string}
   * @memberof TenantSummaryDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TenantSummaryDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TenantSummaryDto
   */
  tier: string;
  /**
   *
   * @type {boolean}
   * @memberof TenantSummaryDto
   */
  active?: boolean | null;
}
/**
 *
 * @export
 * @interface TenantSystemSummaryDto
 */
export interface TenantSystemSummaryDto {
  /**
   *
   * @type {string}
   * @memberof TenantSystemSummaryDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TenantSystemSummaryDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TenantSystemSummaryDto
   */
  tier: string;
  /**
   *
   * @type {boolean}
   * @memberof TenantSystemSummaryDto
   */
  active?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof TenantSystemSummaryDto
   */
  identityPoolId: string;
  /**
   *
   * @type {string}
   * @memberof TenantSystemSummaryDto
   */
  userPoolId: string;
  /**
   *
   * @type {string}
   * @memberof TenantSystemSummaryDto
   */
  systemAdminPolicy?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantSystemSummaryDto
   */
  systemAdminRole?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantSystemSummaryDto
   */
  systemSupportPolicy?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantSystemSummaryDto
   */
  systemSupportRole?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantSystemSummaryDto
   */
  trustRole?: string | null;
}
/**
 *
 * @export
 * @interface TenantUpdateBodyDto
 */
export interface TenantUpdateBodyDto {
  /**
   *
   * @type {string}
   * @memberof TenantUpdateBodyDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TenantUpdateBodyDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TenantUpdateBodyDto
   */
  tier: string;
  /**
   *
   * @type {boolean}
   * @memberof TenantUpdateBodyDto
   */
  active?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof TenantUpdateBodyDto
   */
  debtorId?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantUpdateBodyDto
   */
  address?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantUpdateBodyDto
   */
  companyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantUpdateBodyDto
   */
  contactEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantUpdateBodyDto
   */
  contactFirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantUpdateBodyDto
   */
  contactLastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TenantUpdateBodyDto
   */
  contactPhone?: string | null;
}
/**
 *
 * @export
 * @interface UserDto
 */
export interface UserDto {
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  userName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  email?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserDto
   */
  enabled?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  dateCreated?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  userrole?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  role?: string | null;
  /**
   *
   * @type {number}
   * @memberof UserDto
   */
  customerId?: number | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  confirmedStatus?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  companyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  address?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  postalcode?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  country?: string | null;
}
/**
 *
 * @export
 * @interface WorkStatusLogItemDto
 */
export interface WorkStatusLogItemDto {
  /**
   *
   * @type {number}
   * @memberof WorkStatusLogItemDto
   */
  time?: number;
  /**
   *
   * @type {string}
   * @memberof WorkStatusLogItemDto
   */
  by?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof WorkStatusLogItemDto
   */
  active?: boolean;
}

/**
 * AppointmentApi - axios parameter creator
 * @export
 */
export const AppointmentApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {AppointmentDto} [appointmentDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAddAppointment: async (
      appointmentDto?: AppointmentDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        appointmentDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentDeleteAppointmentById: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("appointmentDeleteAppointmentById", "id", id);
      const localVarPath = `/appointment/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentGetAppointments: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AppointmentDto} [appointmentDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentUpdateAppointment: async (
      appointmentDto?: AppointmentDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/appointment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        appointmentDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AppointmentApi - functional programming interface
 * @export
 */
export const AppointmentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AppointmentApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AppointmentDto} [appointmentDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentAddAppointment(
      appointmentDto?: AppointmentDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appointmentAddAppointment(
          appointmentDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AppointmentApi.appointmentAddAppointment"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentDeleteAppointmentById(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appointmentDeleteAppointmentById(
          id,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AppointmentApi.appointmentDeleteAppointmentById"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentGetAppointments(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AppointmentDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appointmentGetAppointments(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AppointmentApi.appointmentGetAppointments"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {AppointmentDto} [appointmentDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appointmentUpdateAppointment(
      appointmentDto?: AppointmentDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appointmentUpdateAppointment(
          appointmentDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AppointmentApi.appointmentUpdateAppointment"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * AppointmentApi - factory interface
 * @export
 */
export const AppointmentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AppointmentApiFp(configuration);
  return {
    /**
     *
     * @param {AppointmentDto} [appointmentDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentAddAppointment(
      appointmentDto?: AppointmentDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AppointmentDto> {
      return localVarFp
        .appointmentAddAppointment(appointmentDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentDeleteAppointmentById(
      id: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .appointmentDeleteAppointmentById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentGetAppointments(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<AppointmentDto>> {
      return localVarFp
        .appointmentGetAppointments(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AppointmentDto} [appointmentDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appointmentUpdateAppointment(
      appointmentDto?: AppointmentDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .appointmentUpdateAppointment(appointmentDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AppointmentApi - object-oriented interface
 * @export
 * @class AppointmentApi
 * @extends {BaseAPI}
 */
export class AppointmentApi extends BaseAPI {
  /**
   *
   * @param {AppointmentDto} [appointmentDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentAddAppointment(
    appointmentDto?: AppointmentDto,
    options?: RawAxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentAddAppointment(appointmentDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentDeleteAppointmentById(
    id: number,
    options?: RawAxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentDeleteAppointmentById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentGetAppointments(options?: RawAxiosRequestConfig) {
    return AppointmentApiFp(this.configuration)
      .appointmentGetAppointments(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AppointmentDto} [appointmentDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public appointmentUpdateAppointment(
    appointmentDto?: AppointmentDto,
    options?: RawAxiosRequestConfig,
  ) {
    return AppointmentApiFp(this.configuration)
      .appointmentUpdateAppointment(appointmentDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BillOfMaterialsApi - axios parameter creator
 * @export
 */
export const BillOfMaterialsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} productId
     * @param {BillOfMaterialTemplateDto} [billOfMaterialTemplateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billOfMaterialsAddBOMTemplateForProduct: async (
      productId: number,
      billOfMaterialTemplateDto?: BillOfMaterialTemplateDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'productId' is not null or undefined
      assertParamExists(
        "billOfMaterialsAddBOMTemplateForProduct",
        "productId",
        productId,
      );
      const localVarPath = `/bom/templates/forProduct/{productId}`.replace(
        `{${"productId"}}`,
        encodeURIComponent(String(productId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        billOfMaterialTemplateDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} orderId
     * @param {BillOfMaterialDto} [billOfMaterialDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billOfMaterialsCreateBOMForOrder: async (
      orderId: number,
      billOfMaterialDto?: BillOfMaterialDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("billOfMaterialsCreateBOMForOrder", "orderId", orderId);
      const localVarPath = `/orders/order/{orderId}/bom`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        billOfMaterialDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billOfMaterialsGetBOMForOrder: async (
      orderId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("billOfMaterialsGetBOMForOrder", "orderId", orderId);
      const localVarPath = `/orders/order/{orderId}/bom`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billOfMaterialsGetBOMTemplatesForProduct: async (
      productId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'productId' is not null or undefined
      assertParamExists(
        "billOfMaterialsGetBOMTemplatesForProduct",
        "productId",
        productId,
      );
      const localVarPath = `/bom/templates/forProduct/{productId}`.replace(
        `{${"productId"}}`,
        encodeURIComponent(String(productId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} productId
     * @param {BillOfMaterialTemplateDto} [billOfMaterialTemplateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billOfMaterialsUpdateBOMTemplateForProduct: async (
      productId: number,
      billOfMaterialTemplateDto?: BillOfMaterialTemplateDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'productId' is not null or undefined
      assertParamExists(
        "billOfMaterialsUpdateBOMTemplateForProduct",
        "productId",
        productId,
      );
      const localVarPath = `/bom/templates/forProduct/{productId}`.replace(
        `{${"productId"}}`,
        encodeURIComponent(String(productId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        billOfMaterialTemplateDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} orderId
     * @param {number} bomItemId
     * @param {BillOfMaterialItemDto} [billOfMaterialItemDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billOfMaterialsUpdateOrderBomItem: async (
      orderId: number,
      bomItemId: number,
      billOfMaterialItemDto?: BillOfMaterialItemDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists(
        "billOfMaterialsUpdateOrderBomItem",
        "orderId",
        orderId,
      );
      // verify required parameter 'bomItemId' is not null or undefined
      assertParamExists(
        "billOfMaterialsUpdateOrderBomItem",
        "bomItemId",
        bomItemId,
      );
      const localVarPath = `/orders/order/{orderId}/bom/item/{bomItemId}`
        .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
        .replace(`{${"bomItemId"}}`, encodeURIComponent(String(bomItemId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        billOfMaterialItemDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BillOfMaterialsApi - functional programming interface
 * @export
 */
export const BillOfMaterialsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    BillOfMaterialsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} productId
     * @param {BillOfMaterialTemplateDto} [billOfMaterialTemplateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billOfMaterialsAddBOMTemplateForProduct(
      productId: number,
      billOfMaterialTemplateDto?: BillOfMaterialTemplateDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BillOfMaterialTemplateDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billOfMaterialsAddBOMTemplateForProduct(
          productId,
          billOfMaterialTemplateDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "BillOfMaterialsApi.billOfMaterialsAddBOMTemplateForProduct"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} orderId
     * @param {BillOfMaterialDto} [billOfMaterialDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billOfMaterialsCreateBOMForOrder(
      orderId: number,
      billOfMaterialDto?: BillOfMaterialDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billOfMaterialsCreateBOMForOrder(
          orderId,
          billOfMaterialDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "BillOfMaterialsApi.billOfMaterialsCreateBOMForOrder"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billOfMaterialsGetBOMForOrder(
      orderId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: Array<any> }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billOfMaterialsGetBOMForOrder(
          orderId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "BillOfMaterialsApi.billOfMaterialsGetBOMForOrder"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billOfMaterialsGetBOMTemplatesForProduct(
      productId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<BillOfMaterialTemplateDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billOfMaterialsGetBOMTemplatesForProduct(
          productId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "BillOfMaterialsApi.billOfMaterialsGetBOMTemplatesForProduct"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} productId
     * @param {BillOfMaterialTemplateDto} [billOfMaterialTemplateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billOfMaterialsUpdateBOMTemplateForProduct(
      productId: number,
      billOfMaterialTemplateDto?: BillOfMaterialTemplateDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billOfMaterialsUpdateBOMTemplateForProduct(
          productId,
          billOfMaterialTemplateDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "BillOfMaterialsApi.billOfMaterialsUpdateBOMTemplateForProduct"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} orderId
     * @param {number} bomItemId
     * @param {BillOfMaterialItemDto} [billOfMaterialItemDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billOfMaterialsUpdateOrderBomItem(
      orderId: number,
      bomItemId: number,
      billOfMaterialItemDto?: BillOfMaterialItemDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billOfMaterialsUpdateOrderBomItem(
          orderId,
          bomItemId,
          billOfMaterialItemDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "BillOfMaterialsApi.billOfMaterialsUpdateOrderBomItem"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * BillOfMaterialsApi - factory interface
 * @export
 */
export const BillOfMaterialsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BillOfMaterialsApiFp(configuration);
  return {
    /**
     *
     * @param {number} productId
     * @param {BillOfMaterialTemplateDto} [billOfMaterialTemplateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billOfMaterialsAddBOMTemplateForProduct(
      productId: number,
      billOfMaterialTemplateDto?: BillOfMaterialTemplateDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<BillOfMaterialTemplateDto> {
      return localVarFp
        .billOfMaterialsAddBOMTemplateForProduct(
          productId,
          billOfMaterialTemplateDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} orderId
     * @param {BillOfMaterialDto} [billOfMaterialDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billOfMaterialsCreateBOMForOrder(
      orderId: number,
      billOfMaterialDto?: BillOfMaterialDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .billOfMaterialsCreateBOMForOrder(orderId, billOfMaterialDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billOfMaterialsGetBOMForOrder(
      orderId: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: Array<any> }> {
      return localVarFp
        .billOfMaterialsGetBOMForOrder(orderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billOfMaterialsGetBOMTemplatesForProduct(
      productId: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<BillOfMaterialTemplateDto>> {
      return localVarFp
        .billOfMaterialsGetBOMTemplatesForProduct(productId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} productId
     * @param {BillOfMaterialTemplateDto} [billOfMaterialTemplateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billOfMaterialsUpdateBOMTemplateForProduct(
      productId: number,
      billOfMaterialTemplateDto?: BillOfMaterialTemplateDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .billOfMaterialsUpdateBOMTemplateForProduct(
          productId,
          billOfMaterialTemplateDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} orderId
     * @param {number} bomItemId
     * @param {BillOfMaterialItemDto} [billOfMaterialItemDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billOfMaterialsUpdateOrderBomItem(
      orderId: number,
      bomItemId: number,
      billOfMaterialItemDto?: BillOfMaterialItemDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .billOfMaterialsUpdateOrderBomItem(
          orderId,
          bomItemId,
          billOfMaterialItemDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BillOfMaterialsApi - object-oriented interface
 * @export
 * @class BillOfMaterialsApi
 * @extends {BaseAPI}
 */
export class BillOfMaterialsApi extends BaseAPI {
  /**
   *
   * @param {number} productId
   * @param {BillOfMaterialTemplateDto} [billOfMaterialTemplateDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillOfMaterialsApi
   */
  public billOfMaterialsAddBOMTemplateForProduct(
    productId: number,
    billOfMaterialTemplateDto?: BillOfMaterialTemplateDto,
    options?: RawAxiosRequestConfig,
  ) {
    return BillOfMaterialsApiFp(this.configuration)
      .billOfMaterialsAddBOMTemplateForProduct(
        productId,
        billOfMaterialTemplateDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} orderId
   * @param {BillOfMaterialDto} [billOfMaterialDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillOfMaterialsApi
   */
  public billOfMaterialsCreateBOMForOrder(
    orderId: number,
    billOfMaterialDto?: BillOfMaterialDto,
    options?: RawAxiosRequestConfig,
  ) {
    return BillOfMaterialsApiFp(this.configuration)
      .billOfMaterialsCreateBOMForOrder(orderId, billOfMaterialDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} orderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillOfMaterialsApi
   */
  public billOfMaterialsGetBOMForOrder(
    orderId: number,
    options?: RawAxiosRequestConfig,
  ) {
    return BillOfMaterialsApiFp(this.configuration)
      .billOfMaterialsGetBOMForOrder(orderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} productId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillOfMaterialsApi
   */
  public billOfMaterialsGetBOMTemplatesForProduct(
    productId: number,
    options?: RawAxiosRequestConfig,
  ) {
    return BillOfMaterialsApiFp(this.configuration)
      .billOfMaterialsGetBOMTemplatesForProduct(productId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} productId
   * @param {BillOfMaterialTemplateDto} [billOfMaterialTemplateDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillOfMaterialsApi
   */
  public billOfMaterialsUpdateBOMTemplateForProduct(
    productId: number,
    billOfMaterialTemplateDto?: BillOfMaterialTemplateDto,
    options?: RawAxiosRequestConfig,
  ) {
    return BillOfMaterialsApiFp(this.configuration)
      .billOfMaterialsUpdateBOMTemplateForProduct(
        productId,
        billOfMaterialTemplateDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} orderId
   * @param {number} bomItemId
   * @param {BillOfMaterialItemDto} [billOfMaterialItemDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillOfMaterialsApi
   */
  public billOfMaterialsUpdateOrderBomItem(
    orderId: number,
    bomItemId: number,
    billOfMaterialItemDto?: BillOfMaterialItemDto,
    options?: RawAxiosRequestConfig,
  ) {
    return BillOfMaterialsApiFp(this.configuration)
      .billOfMaterialsUpdateOrderBomItem(
        orderId,
        bomItemId,
        billOfMaterialItemDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CustomerDetailsDto} [customerDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerCreateCustomer: async (
      customerDetailsDto?: CustomerDetailsDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/customers/customer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        customerDetailsDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerDeleteCustomer: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("customerDeleteCustomer", "id", id);
      const localVarPath = `/customers/customer/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerGetCustomerDetails: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("customerGetCustomerDetails", "id", id);
      const localVarPath = `/customers/customer/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [nameFilter]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerGetCustomerList: async (
      nameFilter?: string,
      page?: number,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (nameFilter !== undefined) {
        localVarQueryParameter["nameFilter"] = nameFilter;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} customerId
     * @param {string} id
     * @param {CustomerDetailsDto} [customerDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerUpdateCustomer: async (
      customerId: number,
      id: string,
      customerDetailsDto?: CustomerDetailsDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("customerUpdateCustomer", "customerId", customerId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("customerUpdateCustomer", "id", id);
      const localVarPath = `/customers/customer/{id}`
        .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        customerDetailsDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CustomerDetailsDto} [customerDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customerCreateCustomer(
      customerDetailsDto?: CustomerDetailsDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CustomerDetailsDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.customerCreateCustomer(
          customerDetailsDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomerApi.customerCreateCustomer"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customerDeleteCustomer(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.customerDeleteCustomer(id, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomerApi.customerDeleteCustomer"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customerGetCustomerDetails(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CustomerDetailsDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.customerGetCustomerDetails(id, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomerApi.customerGetCustomerDetails"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [nameFilter]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customerGetCustomerList(
      nameFilter?: string,
      page?: number,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CustomerGetCustomerList200ResponseInner>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.customerGetCustomerList(
          nameFilter,
          page,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomerApi.customerGetCustomerList"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} customerId
     * @param {string} id
     * @param {CustomerDetailsDto} [customerDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customerUpdateCustomer(
      customerId: number,
      id: string,
      customerDetailsDto?: CustomerDetailsDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CustomerDetailsDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.customerUpdateCustomer(
          customerId,
          id,
          customerDetailsDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomerApi.customerUpdateCustomer"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CustomerApiFp(configuration);
  return {
    /**
     *
     * @param {CustomerDetailsDto} [customerDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerCreateCustomer(
      customerDetailsDto?: CustomerDetailsDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CustomerDetailsDto> {
      return localVarFp
        .customerCreateCustomer(customerDetailsDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerDeleteCustomer(
      id: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .customerDeleteCustomer(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerGetCustomerDetails(
      id: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CustomerDetailsDto> {
      return localVarFp
        .customerGetCustomerDetails(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [nameFilter]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerGetCustomerList(
      nameFilter?: string,
      page?: number,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<CustomerGetCustomerList200ResponseInner>> {
      return localVarFp
        .customerGetCustomerList(nameFilter, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} customerId
     * @param {string} id
     * @param {CustomerDetailsDto} [customerDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerUpdateCustomer(
      customerId: number,
      id: string,
      customerDetailsDto?: CustomerDetailsDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CustomerDetailsDto> {
      return localVarFp
        .customerUpdateCustomer(customerId, id, customerDetailsDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
  /**
   *
   * @param {CustomerDetailsDto} [customerDetailsDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public customerCreateCustomer(
    customerDetailsDto?: CustomerDetailsDto,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomerApiFp(this.configuration)
      .customerCreateCustomer(customerDetailsDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public customerDeleteCustomer(id: number, options?: RawAxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .customerDeleteCustomer(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public customerGetCustomerDetails(
    id: number,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomerApiFp(this.configuration)
      .customerGetCustomerDetails(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [nameFilter]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public customerGetCustomerList(
    nameFilter?: string,
    page?: number,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomerApiFp(this.configuration)
      .customerGetCustomerList(nameFilter, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} customerId
   * @param {string} id
   * @param {CustomerDetailsDto} [customerDetailsDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public customerUpdateCustomer(
    customerId: number,
    id: string,
    customerDetailsDto?: CustomerDetailsDto,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomerApiFp(this.configuration)
      .customerUpdateCustomer(customerId, id, customerDetailsDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DeliveryApi - axios parameter creator
 * @export
 */
export const DeliveryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {DeliveryGetDelivery200Response} [deliveryGetDelivery200Response]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliveryCreateDelivery: async (
      deliveryGetDelivery200Response?: DeliveryGetDelivery200Response,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/delivery`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deliveryGetDelivery200Response,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [orderId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliveryGetDeliveries: async (
      orderId?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/delivery/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (orderId !== undefined) {
        localVarQueryParameter["orderId"] = orderId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} deliveryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliveryGetDelivery: async (
      deliveryId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deliveryId' is not null or undefined
      assertParamExists("deliveryGetDelivery", "deliveryId", deliveryId);
      const localVarPath = `/delivery/details/{deliveryId}`.replace(
        `{${"deliveryId"}}`,
        encodeURIComponent(String(deliveryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} deliveryId
     * @param {DeliveryGetDelivery200Response} [deliveryGetDelivery200Response]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliveryUpdateDelivery: async (
      deliveryId: number,
      deliveryGetDelivery200Response?: DeliveryGetDelivery200Response,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deliveryId' is not null or undefined
      assertParamExists("deliveryUpdateDelivery", "deliveryId", deliveryId);
      const localVarPath = `/delivery/details/{deliveryId}`.replace(
        `{${"deliveryId"}}`,
        encodeURIComponent(String(deliveryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deliveryGetDelivery200Response,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeliveryApi - functional programming interface
 * @export
 */
export const DeliveryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DeliveryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {DeliveryGetDelivery200Response} [deliveryGetDelivery200Response]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deliveryCreateDelivery(
      deliveryGetDelivery200Response?: DeliveryGetDelivery200Response,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deliveryCreateDelivery(
          deliveryGetDelivery200Response,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DeliveryApi.deliveryCreateDelivery"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} [orderId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deliveryGetDeliveries(
      orderId?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DeliveryGetDelivery200Response>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deliveryGetDeliveries(orderId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DeliveryApi.deliveryGetDeliveries"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} deliveryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deliveryGetDelivery(
      deliveryId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeliveryGetDelivery200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deliveryGetDelivery(
          deliveryId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DeliveryApi.deliveryGetDelivery"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} deliveryId
     * @param {DeliveryGetDelivery200Response} [deliveryGetDelivery200Response]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deliveryUpdateDelivery(
      deliveryId: number,
      deliveryGetDelivery200Response?: DeliveryGetDelivery200Response,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deliveryUpdateDelivery(
          deliveryId,
          deliveryGetDelivery200Response,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DeliveryApi.deliveryUpdateDelivery"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DeliveryApi - factory interface
 * @export
 */
export const DeliveryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DeliveryApiFp(configuration);
  return {
    /**
     *
     * @param {DeliveryGetDelivery200Response} [deliveryGetDelivery200Response]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliveryCreateDelivery(
      deliveryGetDelivery200Response?: DeliveryGetDelivery200Response,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<number> {
      return localVarFp
        .deliveryCreateDelivery(deliveryGetDelivery200Response, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [orderId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliveryGetDeliveries(
      orderId?: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DeliveryGetDelivery200Response>> {
      return localVarFp
        .deliveryGetDeliveries(orderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} deliveryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliveryGetDelivery(
      deliveryId: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DeliveryGetDelivery200Response> {
      return localVarFp
        .deliveryGetDelivery(deliveryId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} deliveryId
     * @param {DeliveryGetDelivery200Response} [deliveryGetDelivery200Response]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliveryUpdateDelivery(
      deliveryId: number,
      deliveryGetDelivery200Response?: DeliveryGetDelivery200Response,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .deliveryUpdateDelivery(
          deliveryId,
          deliveryGetDelivery200Response,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DeliveryApi - object-oriented interface
 * @export
 * @class DeliveryApi
 * @extends {BaseAPI}
 */
export class DeliveryApi extends BaseAPI {
  /**
   *
   * @param {DeliveryGetDelivery200Response} [deliveryGetDelivery200Response]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryApi
   */
  public deliveryCreateDelivery(
    deliveryGetDelivery200Response?: DeliveryGetDelivery200Response,
    options?: RawAxiosRequestConfig,
  ) {
    return DeliveryApiFp(this.configuration)
      .deliveryCreateDelivery(deliveryGetDelivery200Response, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [orderId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryApi
   */
  public deliveryGetDeliveries(
    orderId?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return DeliveryApiFp(this.configuration)
      .deliveryGetDeliveries(orderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} deliveryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryApi
   */
  public deliveryGetDelivery(
    deliveryId: number,
    options?: RawAxiosRequestConfig,
  ) {
    return DeliveryApiFp(this.configuration)
      .deliveryGetDelivery(deliveryId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} deliveryId
   * @param {DeliveryGetDelivery200Response} [deliveryGetDelivery200Response]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryApi
   */
  public deliveryUpdateDelivery(
    deliveryId: number,
    deliveryGetDelivery200Response?: DeliveryGetDelivery200Response,
    options?: RawAxiosRequestConfig,
  ) {
    return DeliveryApiFp(this.configuration)
      .deliveryUpdateDelivery(
        deliveryId,
        deliveryGetDelivery200Response,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FormApi - axios parameter creator
 * @export
 */
export const FormApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {FormDetailsDto} [formDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formCreateForm: async (
      formDetailsDto?: FormDetailsDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/forms/form`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        formDetailsDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formDeleteForm: async (
      id: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("formDeleteForm", "id", id);
      const localVarPath = `/forms/form/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formGetFormDetails: async (
      id: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("formGetFormDetails", "id", id);
      const localVarPath = `/forms/form/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formListForms: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/forms/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {FormDetailsDto} [formDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formUpdateForm: async (
      id: string,
      formDetailsDto?: FormDetailsDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("formUpdateForm", "id", id);
      const localVarPath = `/forms/form/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        formDetailsDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FormApi - functional programming interface
 * @export
 */
export const FormApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FormApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {FormDetailsDto} [formDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async formCreateForm(
      formDetailsDto?: FormDetailsDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDetailsDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.formCreateForm(
        formDetailsDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["FormApi.formCreateForm"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async formDeleteForm(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutationResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.formDeleteForm(
        id,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["FormApi.formDeleteForm"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async formGetFormDetails(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDetailsDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.formGetFormDetails(id, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["FormApi.formGetFormDetails"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async formListForms(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<FormSummaryDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.formListForms(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["FormApi.formListForms"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} id
     * @param {FormDetailsDto} [formDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async formUpdateForm(
      id: string,
      formDetailsDto?: FormDetailsDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.formUpdateForm(
        id,
        formDetailsDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["FormApi.formUpdateForm"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FormApi - factory interface
 * @export
 */
export const FormApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FormApiFp(configuration);
  return {
    /**
     *
     * @param {FormDetailsDto} [formDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formCreateForm(
      formDetailsDto?: FormDetailsDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FormDetailsDto> {
      return localVarFp
        .formCreateForm(formDetailsDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formDeleteForm(
      id: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MutationResult> {
      return localVarFp
        .formDeleteForm(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formGetFormDetails(
      id: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FormDetailsDto> {
      return localVarFp
        .formGetFormDetails(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formListForms(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<FormSummaryDto>> {
      return localVarFp
        .formListForms(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {FormDetailsDto} [formDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formUpdateForm(
      id: string,
      formDetailsDto?: FormDetailsDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .formUpdateForm(id, formDetailsDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FormApi - object-oriented interface
 * @export
 * @class FormApi
 * @extends {BaseAPI}
 */
export class FormApi extends BaseAPI {
  /**
   *
   * @param {FormDetailsDto} [formDetailsDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FormApi
   */
  public formCreateForm(
    formDetailsDto?: FormDetailsDto,
    options?: RawAxiosRequestConfig,
  ) {
    return FormApiFp(this.configuration)
      .formCreateForm(formDetailsDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FormApi
   */
  public formDeleteForm(id: string, options?: RawAxiosRequestConfig) {
    return FormApiFp(this.configuration)
      .formDeleteForm(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FormApi
   */
  public formGetFormDetails(id: string, options?: RawAxiosRequestConfig) {
    return FormApiFp(this.configuration)
      .formGetFormDetails(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FormApi
   */
  public formListForms(options?: RawAxiosRequestConfig) {
    return FormApiFp(this.configuration)
      .formListForms(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {FormDetailsDto} [formDetailsDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FormApi
   */
  public formUpdateForm(
    id: string,
    formDetailsDto?: FormDetailsDto,
    options?: RawAxiosRequestConfig,
  ) {
    return FormApiFp(this.configuration)
      .formUpdateForm(id, formDetailsDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MessagingApi - axios parameter creator
 * @export
 */
export const MessagingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagingGetConsultationEventStream: async (
      orderId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists(
        "messagingGetConsultationEventStream",
        "orderId",
        orderId,
      );
      const localVarPath = `/messaging/consultation/o/{orderId}/stream`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagingGetMessageCount: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/messaging/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagingList: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/messaging/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MessageDto} [messageDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagingPostMessage: async (
      messageDto?: MessageDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/messaging/message`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        messageDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} orderId
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagingPostToConsultationEventStream: async (
      orderId: number,
      body?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists(
        "messagingPostToConsultationEventStream",
        "orderId",
        orderId,
      );
      const localVarPath = `/messaging/consultation/o/{orderId}/stream`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MessagingApi - functional programming interface
 * @export
 */
export const MessagingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    MessagingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagingGetConsultationEventStream(
      orderId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.messagingGetConsultationEventStream(
          orderId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "MessagingApi.messagingGetConsultationEventStream"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagingGetMessageCount(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.messagingGetMessageCount(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MessagingApi.messagingGetMessageCount"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagingList(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<MessageDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.messagingList(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MessagingApi.messagingList"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {MessageDto} [messageDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagingPostMessage(
      messageDto?: MessageDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.messagingPostMessage(
          messageDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MessagingApi.messagingPostMessage"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} orderId
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagingPostToConsultationEventStream(
      orderId: number,
      body?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.messagingPostToConsultationEventStream(
          orderId,
          body,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "MessagingApi.messagingPostToConsultationEventStream"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * MessagingApi - factory interface
 * @export
 */
export const MessagingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MessagingApiFp(configuration);
  return {
    /**
     *
     * @param {number} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagingGetConsultationEventStream(
      orderId: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .messagingGetConsultationEventStream(orderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagingGetMessageCount(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<number> {
      return localVarFp
        .messagingGetMessageCount(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagingList(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<MessageDto>> {
      return localVarFp
        .messagingList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {MessageDto} [messageDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagingPostMessage(
      messageDto?: MessageDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .messagingPostMessage(messageDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} orderId
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagingPostToConsultationEventStream(
      orderId: number,
      body?: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .messagingPostToConsultationEventStream(orderId, body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MessagingApi - object-oriented interface
 * @export
 * @class MessagingApi
 * @extends {BaseAPI}
 */
export class MessagingApi extends BaseAPI {
  /**
   *
   * @param {number} orderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagingApi
   */
  public messagingGetConsultationEventStream(
    orderId: number,
    options?: RawAxiosRequestConfig,
  ) {
    return MessagingApiFp(this.configuration)
      .messagingGetConsultationEventStream(orderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagingApi
   */
  public messagingGetMessageCount(options?: RawAxiosRequestConfig) {
    return MessagingApiFp(this.configuration)
      .messagingGetMessageCount(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagingApi
   */
  public messagingList(options?: RawAxiosRequestConfig) {
    return MessagingApiFp(this.configuration)
      .messagingList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MessageDto} [messageDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagingApi
   */
  public messagingPostMessage(
    messageDto?: MessageDto,
    options?: RawAxiosRequestConfig,
  ) {
    return MessagingApiFp(this.configuration)
      .messagingPostMessage(messageDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} orderId
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagingApi
   */
  public messagingPostToConsultationEventStream(
    orderId: number,
    body?: string,
    options?: RawAxiosRequestConfig,
  ) {
    return MessagingApiFp(this.configuration)
      .messagingPostToConsultationEventStream(orderId, body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} orderId
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderAssignUserToOrder: async (
      orderId: number,
      requestBody?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("orderAssignUserToOrder", "orderId", orderId);
      const localVarPath = `/orders/order/{orderId}/assignTo`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CopyOrderRequestDto} [copyOrderRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderCopyOrder: async (
      copyOrderRequestDto?: CopyOrderRequestDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders/order/copy`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        copyOrderRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {OrderCreateDto} [orderCreateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderCreateNewOrder: async (
      orderCreateDto?: OrderCreateDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders/order`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderCreateDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetAllOrderDelayStatistics: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders/order/stats/delays`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetAllOrdersStats: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders/order/stats`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} year
     * @param {number} week
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetDetailedWorkLogForWeekOfYear: async (
      year: number,
      week: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists("orderGetDetailedWorkLogForWeekOfYear", "year", year);
      // verify required parameter 'week' is not null or undefined
      assertParamExists("orderGetDetailedWorkLogForWeekOfYear", "week", week);
      const localVarPath = `/orders/order/stats/worklog/{year}/{week}/details`
        .replace(`{${"year"}}`, encodeURIComponent(String(year)))
        .replace(`{${"week"}}`, encodeURIComponent(String(week)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetInProgressOrderData: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders/in-progress-data`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetOrderDetailsById: async (
      orderId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("orderGetOrderDetailsById", "orderId", orderId);
      const localVarPath = `/orders/order/{orderId}`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} ticketSystem
     * @param {string} ticketId
     * @param {string} [authToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetOrdersByExternalTicketId: async (
      ticketSystem: string,
      ticketId: string,
      authToken?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ticketSystem' is not null or undefined
      assertParamExists(
        "orderGetOrdersByExternalTicketId",
        "ticketSystem",
        ticketSystem,
      );
      // verify required parameter 'ticketId' is not null or undefined
      assertParamExists(
        "orderGetOrdersByExternalTicketId",
        "ticketId",
        ticketId,
      );
      const localVarPath = `/orders/forTicket/{ticketSystem}/{ticketId}`
        .replace(
          `{${"ticketSystem"}}`,
          encodeURIComponent(String(ticketSystem)),
        )
        .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authToken !== undefined) {
        localVarQueryParameter["auth_token"] = authToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetPlannedWorkForYear: async (
      year: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists("orderGetPlannedWorkForYear", "year", year);
      const localVarPath = `/orders/order/stats/plannedwork/{year}`.replace(
        `{${"year"}}`,
        encodeURIComponent(String(year)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetSalesMarginsStats: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders/order/stats/salesmargins`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} year
     * @param {number} week
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetWorkLogForWeekOfYear: async (
      year: number,
      week: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists("orderGetWorkLogForWeekOfYear", "year", year);
      // verify required parameter 'week' is not null or undefined
      assertParamExists("orderGetWorkLogForWeekOfYear", "week", week);
      const localVarPath = `/orders/order/stats/worklog/{year}/{week}`
        .replace(`{${"year"}}`, encodeURIComponent(String(year)))
        .replace(`{${"week"}}`, encodeURIComponent(String(week)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} orderId
     * @param {string} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetWorkStatus: async (
      orderId: number,
      formId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("orderGetWorkStatus", "orderId", orderId);
      // verify required parameter 'formId' is not null or undefined
      assertParamExists("orderGetWorkStatus", "formId", formId);
      const localVarPath = `/orders/order/{orderId}/{formId}/workstatus`
        .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
        .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetWorkedOnToday: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders/order/stats/worked-on-today`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {OrderTypeFilterEnum} [filterType]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderListOrders: async (
      filterType?: OrderTypeFilterEnum,
      page?: number,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (filterType !== undefined) {
        localVarQueryParameter["filterType"] = filterType;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} customerId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderListOrdersForCustomer: async (
      customerId: number,
      page?: number,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("orderListOrdersForCustomer", "customerId", customerId);
      const localVarPath = `/orders/list/{customerId}`.replace(
        `{${"customerId"}}`,
        encodeURIComponent(String(customerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderModifyWorkOnOrder: async (
      body?: any,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders/work`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} orderId
     * @param {string} [formId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderPickUpOrder: async (
      orderId: number,
      formId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("orderPickUpOrder", "orderId", orderId);
      const localVarPath = `/orders/order/{orderId}/pickup`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (formId !== undefined) {
        localVarQueryParameter["formId"] = formId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} orderId
     * @param {string} [formId]
     * @param {boolean} [allUsers]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderReleaseOrder: async (
      orderId: number,
      formId?: string,
      allUsers?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("orderReleaseOrder", "orderId", orderId);
      const localVarPath = `/orders/order/{orderId}/release`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (formId !== undefined) {
        localVarQueryParameter["formId"] = formId;
      }

      if (allUsers !== undefined) {
        localVarQueryParameter["allUsers"] = allUsers;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} orderId
     * @param {number} sequenceNo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderSetSalesMarginCorrection: async (
      orderId: number,
      sequenceNo: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("orderSetSalesMarginCorrection", "orderId", orderId);
      // verify required parameter 'sequenceNo' is not null or undefined
      assertParamExists(
        "orderSetSalesMarginCorrection",
        "sequenceNo",
        sequenceNo,
      );
      const localVarPath =
        `/orders/order/stats/salesmargin/{orderId}/{sequenceNo}`
          .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
          .replace(`{${"sequenceNo"}}`, encodeURIComponent(String(sequenceNo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} orderId
     * @param {OrderDetailsDto} [orderDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderUpdateOrderDetails: async (
      orderId: number,
      orderDetailsDto?: OrderDetailsDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("orderUpdateOrderDetails", "orderId", orderId);
      const localVarPath = `/orders/order/{orderId}`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderDetailsDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} orderId
     * @param {OrderSummaryDto} [orderSummaryDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderUpdateOrderMeta: async (
      orderId: number,
      orderSummaryDto?: OrderSummaryDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("orderUpdateOrderMeta", "orderId", orderId);
      const localVarPath = `/orders/order/{orderId}/summary`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderSummaryDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} orderId
     * @param {number} sequenceNo
     * @param {OrderProductDto} [orderProductDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderUpdateOrderProduct: async (
      orderId: number,
      sequenceNo: number,
      orderProductDto?: OrderProductDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("orderUpdateOrderProduct", "orderId", orderId);
      // verify required parameter 'sequenceNo' is not null or undefined
      assertParamExists("orderUpdateOrderProduct", "sequenceNo", sequenceNo);
      const localVarPath = `/orders/order/{orderId}/product/{sequenceNo}`
        .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
        .replace(`{${"sequenceNo"}}`, encodeURIComponent(String(sequenceNo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderProductDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} orderId
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderAssignUserToOrder(
      orderId: number,
      requestBody?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderAssignUserToOrder(
          orderId,
          requestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderAssignUserToOrder"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {CopyOrderRequestDto} [copyOrderRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderCopyOrder(
      copyOrderRequestDto?: CopyOrderRequestDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.orderCopyOrder(
        copyOrderRequestDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderCopyOrder"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {OrderCreateDto} [orderCreateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderCreateNewOrder(
      orderCreateDto?: OrderCreateDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderSummaryDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderCreateNewOrder(
          orderCreateDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderCreateNewOrder"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderGetAllOrderDelayStatistics(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderGetAllOrderDelayStatistics(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderGetAllOrderDelayStatistics"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderGetAllOrdersStats(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderStateTallyDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderGetAllOrdersStats(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderGetAllOrdersStats"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} year
     * @param {number} week
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderGetDetailedWorkLogForWeekOfYear(
      year: number,
      week: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderGetDetailedWorkLogForWeekOfYear(
          year,
          week,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderGetDetailedWorkLogForWeekOfYear"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderGetInProgressOrderData(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<OrderGetInProgressOrderData200ResponseInner>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderGetInProgressOrderData(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderGetInProgressOrderData"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderGetOrderDetailsById(
      orderId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderDetailsDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderGetOrderDetailsById(
          orderId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderGetOrderDetailsById"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} ticketSystem
     * @param {string} ticketId
     * @param {string} [authToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderGetOrdersByExternalTicketId(
      ticketSystem: string,
      ticketId: string,
      authToken?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<OrderSummaryDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderGetOrdersByExternalTicketId(
          ticketSystem,
          ticketId,
          authToken,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderGetOrdersByExternalTicketId"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderGetPlannedWorkForYear(
      year: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderGetPlannedWorkForYear(
          year,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderGetPlannedWorkForYear"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderGetSalesMarginsStats(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderGetSalesMarginsStats(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderGetSalesMarginsStats"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} year
     * @param {number} week
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderGetWorkLogForWeekOfYear(
      year: number,
      week: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderGetWorkLogForWeekOfYear(
          year,
          week,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderGetWorkLogForWeekOfYear"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} orderId
     * @param {string} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderGetWorkStatus(
      orderId: number,
      formId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<WorkStatusLogItemDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderGetWorkStatus(
          orderId,
          formId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderGetWorkStatus"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderGetWorkedOnToday(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<OrdersWorkedOnFromRecordDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderGetWorkedOnToday(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderGetWorkedOnToday"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {OrderTypeFilterEnum} [filterType]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderListOrders(
      filterType?: OrderTypeFilterEnum,
      page?: number,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.orderListOrders(
        filterType,
        page,
        pageSize,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderListOrders"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} customerId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderListOrdersForCustomer(
      customerId: number,
      page?: number,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderListOrdersForCustomer(
          customerId,
          page,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderListOrdersForCustomer"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderModifyWorkOnOrder(
      body?: any,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderModifyWorkOnOrder(body, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderModifyWorkOnOrder"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} orderId
     * @param {string} [formId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderPickUpOrder(
      orderId: number,
      formId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderPickUpOrder(
          orderId,
          formId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderPickUpOrder"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} orderId
     * @param {string} [formId]
     * @param {boolean} [allUsers]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderReleaseOrder(
      orderId: number,
      formId?: string,
      allUsers?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderReleaseOrder(
          orderId,
          formId,
          allUsers,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderReleaseOrder"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} orderId
     * @param {number} sequenceNo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderSetSalesMarginCorrection(
      orderId: number,
      sequenceNo: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderSetSalesMarginCorrection(
          orderId,
          sequenceNo,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderSetSalesMarginCorrection"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} orderId
     * @param {OrderDetailsDto} [orderDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderUpdateOrderDetails(
      orderId: number,
      orderDetailsDto?: OrderDetailsDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutationResult>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderUpdateOrderDetails(
          orderId,
          orderDetailsDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderUpdateOrderDetails"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} orderId
     * @param {OrderSummaryDto} [orderSummaryDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderUpdateOrderMeta(
      orderId: number,
      orderSummaryDto?: OrderSummaryDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutationResult>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderUpdateOrderMeta(
          orderId,
          orderSummaryDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderUpdateOrderMeta"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} orderId
     * @param {number} sequenceNo
     * @param {OrderProductDto} [orderProductDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderUpdateOrderProduct(
      orderId: number,
      sequenceNo: number,
      orderProductDto?: OrderProductDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.orderUpdateOrderProduct(
          orderId,
          sequenceNo,
          orderProductDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderApi.orderUpdateOrderProduct"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrderApiFp(configuration);
  return {
    /**
     *
     * @param {number} orderId
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderAssignUserToOrder(
      orderId: number,
      requestBody?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .orderAssignUserToOrder(orderId, requestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CopyOrderRequestDto} [copyOrderRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderCopyOrder(
      copyOrderRequestDto?: CopyOrderRequestDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<number> {
      return localVarFp
        .orderCopyOrder(copyOrderRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {OrderCreateDto} [orderCreateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderCreateNewOrder(
      orderCreateDto?: OrderCreateDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<OrderSummaryDto> {
      return localVarFp
        .orderCreateNewOrder(orderCreateDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetAllOrderDelayStatistics(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .orderGetAllOrderDelayStatistics(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetAllOrdersStats(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<OrderStateTallyDto> {
      return localVarFp
        .orderGetAllOrdersStats(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} year
     * @param {number} week
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetDetailedWorkLogForWeekOfYear(
      year: number,
      week: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .orderGetDetailedWorkLogForWeekOfYear(year, week, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetInProgressOrderData(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<OrderGetInProgressOrderData200ResponseInner>> {
      return localVarFp
        .orderGetInProgressOrderData(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetOrderDetailsById(
      orderId: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<OrderDetailsDto> {
      return localVarFp
        .orderGetOrderDetailsById(orderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} ticketSystem
     * @param {string} ticketId
     * @param {string} [authToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetOrdersByExternalTicketId(
      ticketSystem: string,
      ticketId: string,
      authToken?: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<OrderSummaryDto>> {
      return localVarFp
        .orderGetOrdersByExternalTicketId(
          ticketSystem,
          ticketId,
          authToken,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetPlannedWorkForYear(
      year: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .orderGetPlannedWorkForYear(year, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetSalesMarginsStats(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .orderGetSalesMarginsStats(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} year
     * @param {number} week
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetWorkLogForWeekOfYear(
      year: number,
      week: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .orderGetWorkLogForWeekOfYear(year, week, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} orderId
     * @param {string} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetWorkStatus(
      orderId: number,
      formId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<WorkStatusLogItemDto>> {
      return localVarFp
        .orderGetWorkStatus(orderId, formId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderGetWorkedOnToday(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<OrdersWorkedOnFromRecordDto>> {
      return localVarFp
        .orderGetWorkedOnToday(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {OrderTypeFilterEnum} [filterType]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderListOrders(
      filterType?: OrderTypeFilterEnum,
      page?: number,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .orderListOrders(filterType, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} customerId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderListOrdersForCustomer(
      customerId: number,
      page?: number,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .orderListOrdersForCustomer(customerId, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderModifyWorkOnOrder(
      body?: any,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .orderModifyWorkOnOrder(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} orderId
     * @param {string} [formId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderPickUpOrder(
      orderId: number,
      formId?: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .orderPickUpOrder(orderId, formId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} orderId
     * @param {string} [formId]
     * @param {boolean} [allUsers]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderReleaseOrder(
      orderId: number,
      formId?: string,
      allUsers?: boolean,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .orderReleaseOrder(orderId, formId, allUsers, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} orderId
     * @param {number} sequenceNo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderSetSalesMarginCorrection(
      orderId: number,
      sequenceNo: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .orderSetSalesMarginCorrection(orderId, sequenceNo, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} orderId
     * @param {OrderDetailsDto} [orderDetailsDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderUpdateOrderDetails(
      orderId: number,
      orderDetailsDto?: OrderDetailsDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MutationResult> {
      return localVarFp
        .orderUpdateOrderDetails(orderId, orderDetailsDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} orderId
     * @param {OrderSummaryDto} [orderSummaryDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderUpdateOrderMeta(
      orderId: number,
      orderSummaryDto?: OrderSummaryDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MutationResult> {
      return localVarFp
        .orderUpdateOrderMeta(orderId, orderSummaryDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} orderId
     * @param {number} sequenceNo
     * @param {OrderProductDto} [orderProductDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderUpdateOrderProduct(
      orderId: number,
      sequenceNo: number,
      orderProductDto?: OrderProductDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .orderUpdateOrderProduct(orderId, sequenceNo, orderProductDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
  /**
   *
   * @param {number} orderId
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderAssignUserToOrder(
    orderId: number,
    requestBody?: Array<string>,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderAssignUserToOrder(orderId, requestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CopyOrderRequestDto} [copyOrderRequestDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderCopyOrder(
    copyOrderRequestDto?: CopyOrderRequestDto,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderCopyOrder(copyOrderRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {OrderCreateDto} [orderCreateDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderCreateNewOrder(
    orderCreateDto?: OrderCreateDto,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderCreateNewOrder(orderCreateDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderGetAllOrderDelayStatistics(options?: RawAxiosRequestConfig) {
    return OrderApiFp(this.configuration)
      .orderGetAllOrderDelayStatistics(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderGetAllOrdersStats(options?: RawAxiosRequestConfig) {
    return OrderApiFp(this.configuration)
      .orderGetAllOrdersStats(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} year
   * @param {number} week
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderGetDetailedWorkLogForWeekOfYear(
    year: number,
    week: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderGetDetailedWorkLogForWeekOfYear(year, week, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderGetInProgressOrderData(options?: RawAxiosRequestConfig) {
    return OrderApiFp(this.configuration)
      .orderGetInProgressOrderData(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} orderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderGetOrderDetailsById(
    orderId: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderGetOrderDetailsById(orderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} ticketSystem
   * @param {string} ticketId
   * @param {string} [authToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderGetOrdersByExternalTicketId(
    ticketSystem: string,
    ticketId: string,
    authToken?: string,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderGetOrdersByExternalTicketId(
        ticketSystem,
        ticketId,
        authToken,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} year
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderGetPlannedWorkForYear(
    year: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderGetPlannedWorkForYear(year, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderGetSalesMarginsStats(options?: RawAxiosRequestConfig) {
    return OrderApiFp(this.configuration)
      .orderGetSalesMarginsStats(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} year
   * @param {number} week
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderGetWorkLogForWeekOfYear(
    year: number,
    week: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderGetWorkLogForWeekOfYear(year, week, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} orderId
   * @param {string} formId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderGetWorkStatus(
    orderId: number,
    formId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderGetWorkStatus(orderId, formId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderGetWorkedOnToday(options?: RawAxiosRequestConfig) {
    return OrderApiFp(this.configuration)
      .orderGetWorkedOnToday(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {OrderTypeFilterEnum} [filterType]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderListOrders(
    filterType?: OrderTypeFilterEnum,
    page?: number,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderListOrders(filterType, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} customerId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderListOrdersForCustomer(
    customerId: number,
    page?: number,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderListOrdersForCustomer(customerId, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderModifyWorkOnOrder(body?: any, options?: RawAxiosRequestConfig) {
    return OrderApiFp(this.configuration)
      .orderModifyWorkOnOrder(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} orderId
   * @param {string} [formId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderPickUpOrder(
    orderId: number,
    formId?: string,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderPickUpOrder(orderId, formId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} orderId
   * @param {string} [formId]
   * @param {boolean} [allUsers]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderReleaseOrder(
    orderId: number,
    formId?: string,
    allUsers?: boolean,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderReleaseOrder(orderId, formId, allUsers, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} orderId
   * @param {number} sequenceNo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderSetSalesMarginCorrection(
    orderId: number,
    sequenceNo: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderSetSalesMarginCorrection(orderId, sequenceNo, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} orderId
   * @param {OrderDetailsDto} [orderDetailsDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderUpdateOrderDetails(
    orderId: number,
    orderDetailsDto?: OrderDetailsDto,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderUpdateOrderDetails(orderId, orderDetailsDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} orderId
   * @param {OrderSummaryDto} [orderSummaryDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderUpdateOrderMeta(
    orderId: number,
    orderSummaryDto?: OrderSummaryDto,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderUpdateOrderMeta(orderId, orderSummaryDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} orderId
   * @param {number} sequenceNo
   * @param {OrderProductDto} [orderProductDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderApi
   */
  public orderUpdateOrderProduct(
    orderId: number,
    sequenceNo: number,
    orderProductDto?: OrderProductDto,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderApiFp(this.configuration)
      .orderUpdateOrderProduct(orderId, sequenceNo, orderProductDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {ProductCreateProductRequest} [productCreateProductRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productCreateProduct: async (
      productCreateProductRequest?: ProductCreateProductRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/products/product`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        productCreateProductRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productDeleteProduct: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("productDeleteProduct", "id", id);
      const localVarPath = `/products/product/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productListProducts: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/products/products`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ProductDto} [productDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productUpdateProduct: async (
      productDto?: ProductDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/products/product`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        productDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ProductCreateProductRequest} [productCreateProductRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async productCreateProduct(
      productCreateProductRequest?: ProductCreateProductRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.productCreateProduct(
          productCreateProductRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ProductApi.productCreateProduct"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async productDeleteProduct(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.productDeleteProduct(id, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ProductApi.productDeleteProduct"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async productListProducts(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ProductDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.productListProducts(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ProductApi.productListProducts"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {ProductDto} [productDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async productUpdateProduct(
      productDto?: ProductDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.productUpdateProduct(
          productDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ProductApi.productUpdateProduct"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ProductApiFp(configuration);
  return {
    /**
     *
     * @param {ProductCreateProductRequest} [productCreateProductRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productCreateProduct(
      productCreateProductRequest?: ProductCreateProductRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ProductDto> {
      return localVarFp
        .productCreateProduct(productCreateProductRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productDeleteProduct(
      id: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .productDeleteProduct(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productListProducts(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<ProductDto>> {
      return localVarFp
        .productListProducts(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ProductDto} [productDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productUpdateProduct(
      productDto?: ProductDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .productUpdateProduct(productDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
  /**
   *
   * @param {ProductCreateProductRequest} [productCreateProductRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public productCreateProduct(
    productCreateProductRequest?: ProductCreateProductRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ProductApiFp(this.configuration)
      .productCreateProduct(productCreateProductRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public productDeleteProduct(id: number, options?: RawAxiosRequestConfig) {
    return ProductApiFp(this.configuration)
      .productDeleteProduct(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public productListProducts(options?: RawAxiosRequestConfig) {
    return ProductApiFp(this.configuration)
      .productListProducts(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ProductDto} [productDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public productUpdateProduct(
    productDto?: ProductDto,
    options?: RawAxiosRequestConfig,
  ) {
    return ProductApiFp(this.configuration)
      .productUpdateProduct(productDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} [q]
     * @param {number} [size]
     * @param {string} [entityType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSearch: async (
      q?: string,
      size?: number,
      entityType?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (q !== undefined) {
        localVarQueryParameter["q"] = q;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (entityType !== undefined) {
        localVarQueryParameter["entityType"] = entityType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [q]
     * @param {number} [size]
     * @param {string} [entityType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchSearch(
      q?: string,
      size?: number,
      entityType?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EnvelopedSearchResultDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchSearch(
        q,
        size,
        entityType,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["SearchApi.searchSearch"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SearchApiFp(configuration);
  return {
    /**
     *
     * @param {string} [q]
     * @param {number} [size]
     * @param {string} [entityType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSearch(
      q?: string,
      size?: number,
      entityType?: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<EnvelopedSearchResultDto> {
      return localVarFp
        .searchSearch(q, size, entityType, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
  /**
   *
   * @param {string} [q]
   * @param {number} [size]
   * @param {string} [entityType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public searchSearch(
    q?: string,
    size?: number,
    entityType?: string,
    options?: RawAxiosRequestConfig,
  ) {
    return SearchApiFp(this.configuration)
      .searchSearch(q, size, entityType, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ShoppingListApi - axios parameter creator
 * @export
 */
export const ShoppingListApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {ShoppingListItemDto} [shoppingListItemDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shoppingListAddShoppingListItem: async (
      shoppingListItemDto?: ShoppingListItemDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/shoppinglist/item`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        shoppingListItemDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shoppingListDeleteShoppingListItem: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("shoppingListDeleteShoppingListItem", "id", id);
      const localVarPath = `/shoppinglist/item/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shoppingListListShoppingListItems: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/shoppinglist/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {ShoppingListItemDto} [shoppingListItemDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shoppingListUpdateShoppingListItem: async (
      id: number,
      shoppingListItemDto?: ShoppingListItemDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("shoppingListUpdateShoppingListItem", "id", id);
      const localVarPath = `/shoppinglist/item/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        shoppingListItemDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ShoppingListApi - functional programming interface
 * @export
 */
export const ShoppingListApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ShoppingListApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ShoppingListItemDto} [shoppingListItemDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shoppingListAddShoppingListItem(
      shoppingListItemDto?: ShoppingListItemDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.shoppingListAddShoppingListItem(
          shoppingListItemDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ShoppingListApi.shoppingListAddShoppingListItem"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shoppingListDeleteShoppingListItem(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.shoppingListDeleteShoppingListItem(
          id,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "ShoppingListApi.shoppingListDeleteShoppingListItem"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shoppingListListShoppingListItems(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ShoppingListItemDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.shoppingListListShoppingListItems(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "ShoppingListApi.shoppingListListShoppingListItems"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} id
     * @param {ShoppingListItemDto} [shoppingListItemDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shoppingListUpdateShoppingListItem(
      id: number,
      shoppingListItemDto?: ShoppingListItemDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.shoppingListUpdateShoppingListItem(
          id,
          shoppingListItemDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "ShoppingListApi.shoppingListUpdateShoppingListItem"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ShoppingListApi - factory interface
 * @export
 */
export const ShoppingListApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ShoppingListApiFp(configuration);
  return {
    /**
     *
     * @param {ShoppingListItemDto} [shoppingListItemDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shoppingListAddShoppingListItem(
      shoppingListItemDto?: ShoppingListItemDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .shoppingListAddShoppingListItem(shoppingListItemDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shoppingListDeleteShoppingListItem(
      id: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .shoppingListDeleteShoppingListItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shoppingListListShoppingListItems(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<ShoppingListItemDto>> {
      return localVarFp
        .shoppingListListShoppingListItems(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {ShoppingListItemDto} [shoppingListItemDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shoppingListUpdateShoppingListItem(
      id: number,
      shoppingListItemDto?: ShoppingListItemDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .shoppingListUpdateShoppingListItem(id, shoppingListItemDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ShoppingListApi - object-oriented interface
 * @export
 * @class ShoppingListApi
 * @extends {BaseAPI}
 */
export class ShoppingListApi extends BaseAPI {
  /**
   *
   * @param {ShoppingListItemDto} [shoppingListItemDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShoppingListApi
   */
  public shoppingListAddShoppingListItem(
    shoppingListItemDto?: ShoppingListItemDto,
    options?: RawAxiosRequestConfig,
  ) {
    return ShoppingListApiFp(this.configuration)
      .shoppingListAddShoppingListItem(shoppingListItemDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShoppingListApi
   */
  public shoppingListDeleteShoppingListItem(
    id: number,
    options?: RawAxiosRequestConfig,
  ) {
    return ShoppingListApiFp(this.configuration)
      .shoppingListDeleteShoppingListItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShoppingListApi
   */
  public shoppingListListShoppingListItems(options?: RawAxiosRequestConfig) {
    return ShoppingListApiFp(this.configuration)
      .shoppingListListShoppingListItems(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {ShoppingListItemDto} [shoppingListItemDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShoppingListApi
   */
  public shoppingListUpdateShoppingListItem(
    id: number,
    shoppingListItemDto?: ShoppingListItemDto,
    options?: RawAxiosRequestConfig,
  ) {
    return ShoppingListApiFp(this.configuration)
      .shoppingListUpdateShoppingListItem(id, shoppingListItemDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StockManagementApi - axios parameter creator
 * @export
 */
export const StockManagementApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} articleId
     * @param {StockManagementGetArticleLocations200ResponseInner} [stockManagementGetArticleLocations200ResponseInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementAddArticleMutation: async (
      articleId: number,
      stockManagementGetArticleLocations200ResponseInner?: StockManagementGetArticleLocations200ResponseInner,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'articleId' is not null or undefined
      assertParamExists(
        "stockManagementAddArticleMutation",
        "articleId",
        articleId,
      );
      const localVarPath =
        `/stock/articles/article/{articleId}/mutations`.replace(
          `{${"articleId"}}`,
          encodeURIComponent(String(articleId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        stockManagementGetArticleLocations200ResponseInner,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StockArticleDto} [stockArticleDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementAddNewArticle: async (
      stockArticleDto?: StockArticleDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/stock/articles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        stockArticleDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StockCategoryDto} [stockCategoryDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementAddNewCategory: async (
      stockCategoryDto?: StockCategoryDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/stock/categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        stockCategoryDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StockWarehouseDto} [stockWarehouseDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementAddNewWarehouse: async (
      stockWarehouseDto?: StockWarehouseDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/stock/warehouses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        stockWarehouseDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} warehouseId
     * @param {StockStorageLocationDto} [stockStorageLocationDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementAddWarehouseLocations: async (
      warehouseId: number,
      stockStorageLocationDto?: StockStorageLocationDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'warehouseId' is not null or undefined
      assertParamExists(
        "stockManagementAddWarehouseLocations",
        "warehouseId",
        warehouseId,
      );
      const localVarPath = `/stock/warehouse/{warehouseId}/location`.replace(
        `{${"warehouseId"}}`,
        encodeURIComponent(String(warehouseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        stockStorageLocationDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementDeleteArticleById: async (
      articleId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'articleId' is not null or undefined
      assertParamExists(
        "stockManagementDeleteArticleById",
        "articleId",
        articleId,
      );
      const localVarPath = `/stock/articles/article/{articleId}`.replace(
        `{${"articleId"}}`,
        encodeURIComponent(String(articleId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementDeleteCategoryById: async (
      id: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("stockManagementDeleteCategoryById", "id", id);
      const localVarPath = `/stock/categories/category/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} warehouseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementDeleteWarehouseById: async (
      warehouseId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'warehouseId' is not null or undefined
      assertParamExists(
        "stockManagementDeleteWarehouseById",
        "warehouseId",
        warehouseId,
      );
      const localVarPath = `/stock/warehouse/{warehouseId}`.replace(
        `{${"warehouseId"}}`,
        encodeURIComponent(String(warehouseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} locationId
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementDeleteWarehouseLocationById: async (
      warehouseId: number,
      locationId: number,
      force?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'warehouseId' is not null or undefined
      assertParamExists(
        "stockManagementDeleteWarehouseLocationById",
        "warehouseId",
        warehouseId,
      );
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists(
        "stockManagementDeleteWarehouseLocationById",
        "locationId",
        locationId,
      );
      const localVarPath =
        `/stock/warehouse/{warehouseId}/location/{locationId}`
          .replace(
            `{${"warehouseId"}}`,
            encodeURIComponent(String(warehouseId)),
          )
          .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (force !== undefined) {
        localVarQueryParameter["force"] = force;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} articleId2
     * @param {number} [articleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetArticleDetailsById: async (
      articleId2: string,
      articleId?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'articleId2' is not null or undefined
      assertParamExists(
        "stockManagementGetArticleDetailsById",
        "articleId2",
        articleId2,
      );
      const localVarPath = `/stock/articles/article/{articleId}`.replace(
        `{${"articleId"}}`,
        encodeURIComponent(String(articleId2)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (articleId !== undefined) {
        localVarQueryParameter["articleId"] = articleId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetArticleLocations: async (
      articleId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'articleId' is not null or undefined
      assertParamExists(
        "stockManagementGetArticleLocations",
        "articleId",
        articleId,
      );
      const localVarPath =
        `/stock/articles/article/{articleId}/locations`.replace(
          `{${"articleId"}}`,
          encodeURIComponent(String(articleId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} articleId
     * @param {number} [locationId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetArticleMutations: async (
      articleId: number,
      locationId?: number,
      start?: string,
      end?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'articleId' is not null or undefined
      assertParamExists(
        "stockManagementGetArticleMutations",
        "articleId",
        articleId,
      );
      const localVarPath =
        `/stock/articles/article/{articleId}/mutations`.replace(
          `{${"articleId"}}`,
          encodeURIComponent(String(articleId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (locationId !== undefined) {
        localVarQueryParameter["locationId"] = locationId;
      }

      if (start !== undefined) {
        localVarQueryParameter["start"] =
          (start as any) instanceof Date ? (start as any).toISOString() : start;
      }

      if (end !== undefined) {
        localVarQueryParameter["end"] =
          (end as any) instanceof Date ? (end as any).toISOString() : end;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch a list of stocked articles
     * @param {number} [categoryId]
     * @param {boolean} [full]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetArticles: async (
      categoryId?: number,
      full?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/stock/articles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (categoryId !== undefined) {
        localVarQueryParameter["categoryId"] = categoryId;
      }

      if (full !== undefined) {
        localVarQueryParameter["full"] = full;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch a list of stock item categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetCategories: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/stock/categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetWarehouseLocationArticles: async (
      warehouseId: number,
      locationId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'warehouseId' is not null or undefined
      assertParamExists(
        "stockManagementGetWarehouseLocationArticles",
        "warehouseId",
        warehouseId,
      );
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists(
        "stockManagementGetWarehouseLocationArticles",
        "locationId",
        locationId,
      );
      const localVarPath =
        `/stock/warehouse/{warehouseId}/location/{locationId}/articles`
          .replace(
            `{${"warehouseId"}}`,
            encodeURIComponent(String(warehouseId)),
          )
          .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetWarehouseLocationDetails: async (
      warehouseId: number,
      locationId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'warehouseId' is not null or undefined
      assertParamExists(
        "stockManagementGetWarehouseLocationDetails",
        "warehouseId",
        warehouseId,
      );
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists(
        "stockManagementGetWarehouseLocationDetails",
        "locationId",
        locationId,
      );
      const localVarPath =
        `/stock/warehouse/{warehouseId}/location/{locationId}`
          .replace(
            `{${"warehouseId"}}`,
            encodeURIComponent(String(warehouseId)),
          )
          .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetWarehouseLocations: async (
      warehouseId: number,
      page?: number,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'warehouseId' is not null or undefined
      assertParamExists(
        "stockManagementGetWarehouseLocations",
        "warehouseId",
        warehouseId,
      );
      const localVarPath = `/stock/warehouse/{warehouseId}/locations`.replace(
        `{${"warehouseId"}}`,
        encodeURIComponent(String(warehouseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetWarehouses: async (
      page?: number,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/stock/warehouses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} articleId
     * @param {StockArticleDto} [stockArticleDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementUpdateArticleById: async (
      articleId: number,
      stockArticleDto?: StockArticleDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'articleId' is not null or undefined
      assertParamExists(
        "stockManagementUpdateArticleById",
        "articleId",
        articleId,
      );
      const localVarPath = `/stock/articles/article/{articleId}`.replace(
        `{${"articleId"}}`,
        encodeURIComponent(String(articleId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        stockArticleDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {StockCategoryDto} [stockCategoryDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementUpdateCategoryById: async (
      id: number,
      stockCategoryDto?: StockCategoryDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("stockManagementUpdateCategoryById", "id", id);
      const localVarPath = `/stock/categories/category/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        stockCategoryDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} warehouseId
     * @param {StockWarehouseDto} [stockWarehouseDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementUpdateWarehouseById: async (
      warehouseId: number,
      stockWarehouseDto?: StockWarehouseDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'warehouseId' is not null or undefined
      assertParamExists(
        "stockManagementUpdateWarehouseById",
        "warehouseId",
        warehouseId,
      );
      const localVarPath = `/stock/warehouse/{warehouseId}`.replace(
        `{${"warehouseId"}}`,
        encodeURIComponent(String(warehouseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        stockWarehouseDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} locationId
     * @param {StockStorageLocationDto} [stockStorageLocationDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementUpdateWarehouseLocationDetails: async (
      warehouseId: number,
      locationId: number,
      stockStorageLocationDto?: StockStorageLocationDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'warehouseId' is not null or undefined
      assertParamExists(
        "stockManagementUpdateWarehouseLocationDetails",
        "warehouseId",
        warehouseId,
      );
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists(
        "stockManagementUpdateWarehouseLocationDetails",
        "locationId",
        locationId,
      );
      const localVarPath =
        `/stock/warehouse/{warehouseId}/location/{locationId}`
          .replace(
            `{${"warehouseId"}}`,
            encodeURIComponent(String(warehouseId)),
          )
          .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        stockStorageLocationDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StockManagementApi - functional programming interface
 * @export
 */
export const StockManagementApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    StockManagementApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} articleId
     * @param {StockManagementGetArticleLocations200ResponseInner} [stockManagementGetArticleLocations200ResponseInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementAddArticleMutation(
      articleId: number,
      stockManagementGetArticleLocations200ResponseInner?: StockManagementGetArticleLocations200ResponseInner,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementAddArticleMutation(
          articleId,
          stockManagementGetArticleLocations200ResponseInner,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementAddArticleMutation"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {StockArticleDto} [stockArticleDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementAddNewArticle(
      stockArticleDto?: StockArticleDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementAddNewArticle(
          stockArticleDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["StockManagementApi.stockManagementAddNewArticle"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {StockCategoryDto} [stockCategoryDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementAddNewCategory(
      stockCategoryDto?: StockCategoryDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementAddNewCategory(
          stockCategoryDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementAddNewCategory"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {StockWarehouseDto} [stockWarehouseDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementAddNewWarehouse(
      stockWarehouseDto?: StockWarehouseDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StockWarehouseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementAddNewWarehouse(
          stockWarehouseDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementAddNewWarehouse"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} warehouseId
     * @param {StockStorageLocationDto} [stockStorageLocationDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementAddWarehouseLocations(
      warehouseId: number,
      stockStorageLocationDto?: StockStorageLocationDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementAddWarehouseLocations(
          warehouseId,
          stockStorageLocationDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementAddWarehouseLocations"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementDeleteArticleById(
      articleId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementDeleteArticleById(
          articleId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementDeleteArticleById"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementDeleteCategoryById(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementDeleteCategoryById(
          id,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementDeleteCategoryById"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} warehouseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementDeleteWarehouseById(
      warehouseId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementDeleteWarehouseById(
          warehouseId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementDeleteWarehouseById"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} locationId
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementDeleteWarehouseLocationById(
      warehouseId: number,
      locationId: number,
      force?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementDeleteWarehouseLocationById(
          warehouseId,
          locationId,
          force,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementDeleteWarehouseLocationById"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} articleId2
     * @param {number} [articleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementGetArticleDetailsById(
      articleId2: string,
      articleId?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StockArticleDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementGetArticleDetailsById(
          articleId2,
          articleId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementGetArticleDetailsById"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementGetArticleLocations(
      articleId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<
        Array<StockManagementGetArticleLocations200ResponseInner>
      >
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementGetArticleLocations(
          articleId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementGetArticleLocations"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} articleId
     * @param {number} [locationId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementGetArticleMutations(
      articleId: number,
      locationId?: number,
      start?: string,
      end?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<StockMutationDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementGetArticleMutations(
          articleId,
          locationId,
          start,
          end,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementGetArticleMutations"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch a list of stocked articles
     * @param {number} [categoryId]
     * @param {boolean} [full]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementGetArticles(
      categoryId?: number,
      full?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<StockManagementGetArticles200ResponseInner>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementGetArticles(
          categoryId,
          full,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["StockManagementApi.stockManagementGetArticles"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch a list of stock item categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementGetCategories(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<StockCategoryDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementGetCategories(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["StockManagementApi.stockManagementGetCategories"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementGetWarehouseLocationArticles(
      warehouseId: number,
      locationId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<StockArticleDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementGetWarehouseLocationArticles(
          warehouseId,
          locationId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementGetWarehouseLocationArticles"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementGetWarehouseLocationDetails(
      warehouseId: number,
      locationId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StockStorageLocationDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementGetWarehouseLocationDetails(
          warehouseId,
          locationId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementGetWarehouseLocationDetails"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementGetWarehouseLocations(
      warehouseId: number,
      page?: number,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<StockStorageLocationDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementGetWarehouseLocations(
          warehouseId,
          page,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementGetWarehouseLocations"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementGetWarehouses(
      page?: number,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<StockWarehouseDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementGetWarehouses(
          page,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["StockManagementApi.stockManagementGetWarehouses"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} articleId
     * @param {StockArticleDto} [stockArticleDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementUpdateArticleById(
      articleId: number,
      stockArticleDto?: StockArticleDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementUpdateArticleById(
          articleId,
          stockArticleDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementUpdateArticleById"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} id
     * @param {StockCategoryDto} [stockCategoryDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementUpdateCategoryById(
      id: number,
      stockCategoryDto?: StockCategoryDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementUpdateCategoryById(
          id,
          stockCategoryDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementUpdateCategoryById"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} warehouseId
     * @param {StockWarehouseDto} [stockWarehouseDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementUpdateWarehouseById(
      warehouseId: number,
      stockWarehouseDto?: StockWarehouseDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementUpdateWarehouseById(
          warehouseId,
          stockWarehouseDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementUpdateWarehouseById"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} locationId
     * @param {StockStorageLocationDto} [stockStorageLocationDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stockManagementUpdateWarehouseLocationDetails(
      warehouseId: number,
      locationId: number,
      stockStorageLocationDto?: StockStorageLocationDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stockManagementUpdateWarehouseLocationDetails(
          warehouseId,
          locationId,
          stockStorageLocationDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StockManagementApi.stockManagementUpdateWarehouseLocationDetails"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * StockManagementApi - factory interface
 * @export
 */
export const StockManagementApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = StockManagementApiFp(configuration);
  return {
    /**
     *
     * @param {number} articleId
     * @param {StockManagementGetArticleLocations200ResponseInner} [stockManagementGetArticleLocations200ResponseInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementAddArticleMutation(
      articleId: number,
      stockManagementGetArticleLocations200ResponseInner?: StockManagementGetArticleLocations200ResponseInner,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<number> {
      return localVarFp
        .stockManagementAddArticleMutation(
          articleId,
          stockManagementGetArticleLocations200ResponseInner,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StockArticleDto} [stockArticleDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementAddNewArticle(
      stockArticleDto?: StockArticleDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .stockManagementAddNewArticle(stockArticleDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StockCategoryDto} [stockCategoryDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementAddNewCategory(
      stockCategoryDto?: StockCategoryDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .stockManagementAddNewCategory(stockCategoryDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StockWarehouseDto} [stockWarehouseDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementAddNewWarehouse(
      stockWarehouseDto?: StockWarehouseDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<StockWarehouseDto> {
      return localVarFp
        .stockManagementAddNewWarehouse(stockWarehouseDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} warehouseId
     * @param {StockStorageLocationDto} [stockStorageLocationDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementAddWarehouseLocations(
      warehouseId: number,
      stockStorageLocationDto?: StockStorageLocationDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .stockManagementAddWarehouseLocations(
          warehouseId,
          stockStorageLocationDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementDeleteArticleById(
      articleId: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .stockManagementDeleteArticleById(articleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementDeleteCategoryById(
      id: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .stockManagementDeleteCategoryById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} warehouseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementDeleteWarehouseById(
      warehouseId: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .stockManagementDeleteWarehouseById(warehouseId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} locationId
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementDeleteWarehouseLocationById(
      warehouseId: number,
      locationId: number,
      force?: boolean,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .stockManagementDeleteWarehouseLocationById(
          warehouseId,
          locationId,
          force,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} articleId2
     * @param {number} [articleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetArticleDetailsById(
      articleId2: string,
      articleId?: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<StockArticleDto> {
      return localVarFp
        .stockManagementGetArticleDetailsById(articleId2, articleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetArticleLocations(
      articleId: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<StockManagementGetArticleLocations200ResponseInner>> {
      return localVarFp
        .stockManagementGetArticleLocations(articleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} articleId
     * @param {number} [locationId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetArticleMutations(
      articleId: number,
      locationId?: number,
      start?: string,
      end?: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<StockMutationDto>> {
      return localVarFp
        .stockManagementGetArticleMutations(
          articleId,
          locationId,
          start,
          end,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch a list of stocked articles
     * @param {number} [categoryId]
     * @param {boolean} [full]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetArticles(
      categoryId?: number,
      full?: boolean,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<StockManagementGetArticles200ResponseInner>> {
      return localVarFp
        .stockManagementGetArticles(categoryId, full, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch a list of stock item categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetCategories(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<StockCategoryDto>> {
      return localVarFp
        .stockManagementGetCategories(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetWarehouseLocationArticles(
      warehouseId: number,
      locationId: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<StockArticleDto>> {
      return localVarFp
        .stockManagementGetWarehouseLocationArticles(
          warehouseId,
          locationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetWarehouseLocationDetails(
      warehouseId: number,
      locationId: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<StockStorageLocationDto> {
      return localVarFp
        .stockManagementGetWarehouseLocationDetails(
          warehouseId,
          locationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetWarehouseLocations(
      warehouseId: number,
      page?: number,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<StockStorageLocationDto>> {
      return localVarFp
        .stockManagementGetWarehouseLocations(
          warehouseId,
          page,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementGetWarehouses(
      page?: number,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<StockWarehouseDto>> {
      return localVarFp
        .stockManagementGetWarehouses(page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} articleId
     * @param {StockArticleDto} [stockArticleDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementUpdateArticleById(
      articleId: number,
      stockArticleDto?: StockArticleDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .stockManagementUpdateArticleById(articleId, stockArticleDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {StockCategoryDto} [stockCategoryDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementUpdateCategoryById(
      id: number,
      stockCategoryDto?: StockCategoryDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .stockManagementUpdateCategoryById(id, stockCategoryDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} warehouseId
     * @param {StockWarehouseDto} [stockWarehouseDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementUpdateWarehouseById(
      warehouseId: number,
      stockWarehouseDto?: StockWarehouseDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .stockManagementUpdateWarehouseById(
          warehouseId,
          stockWarehouseDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} warehouseId
     * @param {number} locationId
     * @param {StockStorageLocationDto} [stockStorageLocationDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stockManagementUpdateWarehouseLocationDetails(
      warehouseId: number,
      locationId: number,
      stockStorageLocationDto?: StockStorageLocationDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .stockManagementUpdateWarehouseLocationDetails(
          warehouseId,
          locationId,
          stockStorageLocationDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * StockManagementApi - object-oriented interface
 * @export
 * @class StockManagementApi
 * @extends {BaseAPI}
 */
export class StockManagementApi extends BaseAPI {
  /**
   *
   * @param {number} articleId
   * @param {StockManagementGetArticleLocations200ResponseInner} [stockManagementGetArticleLocations200ResponseInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementAddArticleMutation(
    articleId: number,
    stockManagementGetArticleLocations200ResponseInner?: StockManagementGetArticleLocations200ResponseInner,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementAddArticleMutation(
        articleId,
        stockManagementGetArticleLocations200ResponseInner,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StockArticleDto} [stockArticleDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementAddNewArticle(
    stockArticleDto?: StockArticleDto,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementAddNewArticle(stockArticleDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StockCategoryDto} [stockCategoryDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementAddNewCategory(
    stockCategoryDto?: StockCategoryDto,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementAddNewCategory(stockCategoryDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StockWarehouseDto} [stockWarehouseDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementAddNewWarehouse(
    stockWarehouseDto?: StockWarehouseDto,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementAddNewWarehouse(stockWarehouseDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} warehouseId
   * @param {StockStorageLocationDto} [stockStorageLocationDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementAddWarehouseLocations(
    warehouseId: number,
    stockStorageLocationDto?: StockStorageLocationDto,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementAddWarehouseLocations(
        warehouseId,
        stockStorageLocationDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} articleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementDeleteArticleById(
    articleId: number,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementDeleteArticleById(articleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementDeleteCategoryById(
    id: number,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementDeleteCategoryById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} warehouseId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementDeleteWarehouseById(
    warehouseId: number,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementDeleteWarehouseById(warehouseId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} warehouseId
   * @param {number} locationId
   * @param {boolean} [force]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementDeleteWarehouseLocationById(
    warehouseId: number,
    locationId: number,
    force?: boolean,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementDeleteWarehouseLocationById(
        warehouseId,
        locationId,
        force,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} articleId2
   * @param {number} [articleId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementGetArticleDetailsById(
    articleId2: string,
    articleId?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementGetArticleDetailsById(articleId2, articleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} articleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementGetArticleLocations(
    articleId: number,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementGetArticleLocations(articleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} articleId
   * @param {number} [locationId]
   * @param {string} [start]
   * @param {string} [end]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementGetArticleMutations(
    articleId: number,
    locationId?: number,
    start?: string,
    end?: string,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementGetArticleMutations(
        articleId,
        locationId,
        start,
        end,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch a list of stocked articles
   * @param {number} [categoryId]
   * @param {boolean} [full]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementGetArticles(
    categoryId?: number,
    full?: boolean,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementGetArticles(categoryId, full, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch a list of stock item categories
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementGetCategories(options?: RawAxiosRequestConfig) {
    return StockManagementApiFp(this.configuration)
      .stockManagementGetCategories(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} warehouseId
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementGetWarehouseLocationArticles(
    warehouseId: number,
    locationId: number,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementGetWarehouseLocationArticles(
        warehouseId,
        locationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} warehouseId
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementGetWarehouseLocationDetails(
    warehouseId: number,
    locationId: number,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementGetWarehouseLocationDetails(
        warehouseId,
        locationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} warehouseId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementGetWarehouseLocations(
    warehouseId: number,
    page?: number,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementGetWarehouseLocations(
        warehouseId,
        page,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementGetWarehouses(
    page?: number,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementGetWarehouses(page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} articleId
   * @param {StockArticleDto} [stockArticleDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementUpdateArticleById(
    articleId: number,
    stockArticleDto?: StockArticleDto,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementUpdateArticleById(articleId, stockArticleDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {StockCategoryDto} [stockCategoryDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementUpdateCategoryById(
    id: number,
    stockCategoryDto?: StockCategoryDto,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementUpdateCategoryById(id, stockCategoryDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} warehouseId
   * @param {StockWarehouseDto} [stockWarehouseDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementUpdateWarehouseById(
    warehouseId: number,
    stockWarehouseDto?: StockWarehouseDto,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementUpdateWarehouseById(
        warehouseId,
        stockWarehouseDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} warehouseId
   * @param {number} locationId
   * @param {StockStorageLocationDto} [stockStorageLocationDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockManagementApi
   */
  public stockManagementUpdateWarehouseLocationDetails(
    warehouseId: number,
    locationId: number,
    stockStorageLocationDto?: StockStorageLocationDto,
    options?: RawAxiosRequestConfig,
  ) {
    return StockManagementApiFp(this.configuration)
      .stockManagementUpdateWarehouseLocationDetails(
        warehouseId,
        locationId,
        stockStorageLocationDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create a new tenant on the scope system
     * @param {NewTenantBodyDto} [newTenantBodyDto] Data of the tenant to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantCreateNewTenant: async (
      newTenantBodyDto?: NewTenantBodyDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tenants/tenant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newTenantBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantDeleteTenantById: async (
      id: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("tenantDeleteTenantById", "id", id);
      const localVarPath = `/tenants/tenant/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets the FULL details for a specific tenant
     * @param {string} id Identifier of the tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantGetTenantDetailsById: async (
      id: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("tenantGetTenantDetailsById", "id", id);
      const localVarPath = `/tenants/tenant/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets the summary and system details for a specific tenant
     * @param {string} id Identifier of the tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantGetTenantSystemDetailsById: async (
      id: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("tenantGetTenantSystemDetailsById", "id", id);
      const localVarPath = `/tenants/tenant/{id}/system-only`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch a list of summarized registered tenants from the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantGetTenants: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tenants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update the details for a specific tenant
     * @param {string} id Identifier of the tenant
     * @param {TenantUpdateBodyDto} [tenantUpdateBodyDto] Structure containing the new data for the tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantUpdateTenantById: async (
      id: string,
      tenantUpdateBodyDto?: TenantUpdateBodyDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("tenantUpdateTenantById", "id", id);
      const localVarPath = `/tenants/tenant/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantUpdateBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create a new tenant on the scope system
     * @param {NewTenantBodyDto} [newTenantBodyDto] Data of the tenant to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantCreateNewTenant(
      newTenantBodyDto?: NewTenantBodyDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TenantFullDetailsDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantCreateNewTenant(
          newTenantBodyDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantApi.tenantCreateNewTenant"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantDeleteTenantById(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutationResult>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantDeleteTenantById(id, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantApi.tenantDeleteTenantById"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Gets the FULL details for a specific tenant
     * @param {string} id Identifier of the tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantGetTenantDetailsById(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TenantFullDetailsDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantGetTenantDetailsById(id, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantApi.tenantGetTenantDetailsById"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Gets the summary and system details for a specific tenant
     * @param {string} id Identifier of the tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantGetTenantSystemDetailsById(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TenantGetTenantSystemDetailsById200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantGetTenantSystemDetailsById(
          id,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantApi.tenantGetTenantSystemDetailsById"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch a list of summarized registered tenants from the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantGetTenants(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TenantGetTenants200ResponseInner>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantGetTenants(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantApi.tenantGetTenants"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update the details for a specific tenant
     * @param {string} id Identifier of the tenant
     * @param {TenantUpdateBodyDto} [tenantUpdateBodyDto] Structure containing the new data for the tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantUpdateTenantById(
      id: string,
      tenantUpdateBodyDto?: TenantUpdateBodyDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutationResult>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantUpdateTenantById(
          id,
          tenantUpdateBodyDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantApi.tenantUpdateTenantById"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TenantApiFp(configuration);
  return {
    /**
     *
     * @summary Create a new tenant on the scope system
     * @param {NewTenantBodyDto} [newTenantBodyDto] Data of the tenant to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantCreateNewTenant(
      newTenantBodyDto?: NewTenantBodyDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TenantFullDetailsDto> {
      return localVarFp
        .tenantCreateNewTenant(newTenantBodyDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantDeleteTenantById(
      id: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MutationResult> {
      return localVarFp
        .tenantDeleteTenantById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Gets the FULL details for a specific tenant
     * @param {string} id Identifier of the tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantGetTenantDetailsById(
      id: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TenantFullDetailsDto> {
      return localVarFp
        .tenantGetTenantDetailsById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Gets the summary and system details for a specific tenant
     * @param {string} id Identifier of the tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantGetTenantSystemDetailsById(
      id: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TenantGetTenantSystemDetailsById200Response> {
      return localVarFp
        .tenantGetTenantSystemDetailsById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch a list of summarized registered tenants from the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantGetTenants(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<TenantGetTenants200ResponseInner>> {
      return localVarFp
        .tenantGetTenants(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update the details for a specific tenant
     * @param {string} id Identifier of the tenant
     * @param {TenantUpdateBodyDto} [tenantUpdateBodyDto] Structure containing the new data for the tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantUpdateTenantById(
      id: string,
      tenantUpdateBodyDto?: TenantUpdateBodyDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MutationResult> {
      return localVarFp
        .tenantUpdateTenantById(id, tenantUpdateBodyDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
  /**
   *
   * @summary Create a new tenant on the scope system
   * @param {NewTenantBodyDto} [newTenantBodyDto] Data of the tenant to be created
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public tenantCreateNewTenant(
    newTenantBodyDto?: NewTenantBodyDto,
    options?: RawAxiosRequestConfig,
  ) {
    return TenantApiFp(this.configuration)
      .tenantCreateNewTenant(newTenantBodyDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public tenantDeleteTenantById(id: string, options?: RawAxiosRequestConfig) {
    return TenantApiFp(this.configuration)
      .tenantDeleteTenantById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets the FULL details for a specific tenant
   * @param {string} id Identifier of the tenant
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public tenantGetTenantDetailsById(
    id: string,
    options?: RawAxiosRequestConfig,
  ) {
    return TenantApiFp(this.configuration)
      .tenantGetTenantDetailsById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets the summary and system details for a specific tenant
   * @param {string} id Identifier of the tenant
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public tenantGetTenantSystemDetailsById(
    id: string,
    options?: RawAxiosRequestConfig,
  ) {
    return TenantApiFp(this.configuration)
      .tenantGetTenantSystemDetailsById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch a list of summarized registered tenants from the system
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public tenantGetTenants(options?: RawAxiosRequestConfig) {
    return TenantApiFp(this.configuration)
      .tenantGetTenants(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update the details for a specific tenant
   * @param {string} id Identifier of the tenant
   * @param {TenantUpdateBodyDto} [tenantUpdateBodyDto] Structure containing the new data for the tenant
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public tenantUpdateTenantById(
    id: string,
    tenantUpdateBodyDto?: TenantUpdateBodyDto,
    options?: RawAxiosRequestConfig,
  ) {
    return TenantApiFp(this.configuration)
      .tenantUpdateTenantById(id, tenantUpdateBodyDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TenantSettingsApi - axios parameter creator
 * @export
 */
export const TenantSettingsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} subsystem
     * @param {string} setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantSettingsGetTenantSetting: async (
      subsystem: string,
      setting: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'subsystem' is not null or undefined
      assertParamExists(
        "tenantSettingsGetTenantSetting",
        "subsystem",
        subsystem,
      );
      // verify required parameter 'setting' is not null or undefined
      assertParamExists("tenantSettingsGetTenantSetting", "setting", setting);
      const localVarPath = `/tenantsettings/{subsystem}/{setting}`
        .replace(`{${"subsystem"}}`, encodeURIComponent(String(subsystem)))
        .replace(`{${"setting"}}`, encodeURIComponent(String(setting)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantSettingsListSettings: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tenantsettings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<TenantSettingDto>} [tenantSettingDto] Settings array to add/update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantSettingsUpsertTenantSettings: async (
      tenantSettingDto?: Array<TenantSettingDto>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tenantsettings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantSettingDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TenantSettingsApi - functional programming interface
 * @export
 */
export const TenantSettingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    TenantSettingsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} subsystem
     * @param {string} setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantSettingsGetTenantSetting(
      subsystem: string,
      setting: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TenantSettingDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantSettingsGetTenantSetting(
          subsystem,
          setting,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "TenantSettingsApi.tenantSettingsGetTenantSetting"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantSettingsListSettings(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TenantSettingDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantSettingsListSettings(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantSettingsApi.tenantSettingsListSettings"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {Array<TenantSettingDto>} [tenantSettingDto] Settings array to add/update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantSettingsUpsertTenantSettings(
      tenantSettingDto?: Array<TenantSettingDto>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutationResult>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantSettingsUpsertTenantSettings(
          tenantSettingDto,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "TenantSettingsApi.tenantSettingsUpsertTenantSettings"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TenantSettingsApi - factory interface
 * @export
 */
export const TenantSettingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TenantSettingsApiFp(configuration);
  return {
    /**
     *
     * @param {string} subsystem
     * @param {string} setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantSettingsGetTenantSetting(
      subsystem: string,
      setting: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TenantSettingDto> {
      return localVarFp
        .tenantSettingsGetTenantSetting(subsystem, setting, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantSettingsListSettings(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<TenantSettingDto>> {
      return localVarFp
        .tenantSettingsListSettings(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<TenantSettingDto>} [tenantSettingDto] Settings array to add/update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantSettingsUpsertTenantSettings(
      tenantSettingDto?: Array<TenantSettingDto>,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MutationResult> {
      return localVarFp
        .tenantSettingsUpsertTenantSettings(tenantSettingDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TenantSettingsApi - object-oriented interface
 * @export
 * @class TenantSettingsApi
 * @extends {BaseAPI}
 */
export class TenantSettingsApi extends BaseAPI {
  /**
   *
   * @param {string} subsystem
   * @param {string} setting
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantSettingsApi
   */
  public tenantSettingsGetTenantSetting(
    subsystem: string,
    setting: string,
    options?: RawAxiosRequestConfig,
  ) {
    return TenantSettingsApiFp(this.configuration)
      .tenantSettingsGetTenantSetting(subsystem, setting, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantSettingsApi
   */
  public tenantSettingsListSettings(options?: RawAxiosRequestConfig) {
    return TenantSettingsApiFp(this.configuration)
      .tenantSettingsListSettings(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<TenantSettingDto>} [tenantSettingDto] Settings array to add/update
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantSettingsApi
   */
  public tenantSettingsUpsertTenantSettings(
    tenantSettingDto?: Array<TenantSettingDto>,
    options?: RawAxiosRequestConfig,
  ) {
    return TenantSettingsApiFp(this.configuration)
      .tenantSettingsUpsertTenantSettings(tenantSettingDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TransportApi - axios parameter creator
 * @export
 */
export const TransportApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transportGetTransports: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/transport/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransportApi - functional programming interface
 * @export
 */
export const TransportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    TransportApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transportGetTransports(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transportGetTransports(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TransportApi.transportGetTransports"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TransportApi - factory interface
 * @export
 */
export const TransportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TransportApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transportGetTransports(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<any>> {
      return localVarFp
        .transportGetTransports(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TransportApi - object-oriented interface
 * @export
 * @class TransportApi
 * @extends {BaseAPI}
 */
export class TransportApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransportApi
   */
  public transportGetTransports(options?: RawAxiosRequestConfig) {
    return TransportApiFp(this.configuration)
      .transportGetTransports(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create a new user
     * @param {UserDto} [userDto] User data of the user to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCreateUser: async (
      userDto?: UserDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/users/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a user from the scope system
     * @param {string} name username of the user to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDeleteUser: async (
      name: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'name' is not null or undefined
      assertParamExists("userDeleteUser", "name", name);
      const localVarPath = `/users/user/{name}`.replace(
        `{${"name"}}`,
        encodeURIComponent(String(name)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns the list of assignable users (i.e. Factory / Office users)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetAssignableUsers: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/users/assignable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns a list of available users (in the active tenant context)
     * @param {string} [nameFilter] Filter the returned list to only contain users where the username contains this value
     * @param {number} [page] Retrieve a specific page, all when not set
     * @param {number} [pageSize] Page size of the page to return, when page is not set, this is ignored
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userListUsers: async (
      nameFilter?: string,
      page?: number,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (nameFilter !== undefined) {
        localVarQueryParameter["nameFilter"] = nameFilter;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserDto} [userDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUpdateUser: async (
      userDto?: UserDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/users/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create a new user
     * @param {UserDto} [userDto] User data of the user to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userCreateUser(
      userDto?: UserDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userCreateUser(
        userDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UserApi.userCreateUser"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete a user from the scope system
     * @param {string} name username of the user to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userDeleteUser(
      name: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userDeleteUser(
        name,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UserApi.userDeleteUser"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Returns the list of assignable users (i.e. Factory / Office users)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userGetAssignableUsers(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userGetAssignableUsers(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UserApi.userGetAssignableUsers"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Returns a list of available users (in the active tenant context)
     * @param {string} [nameFilter] Filter the returned list to only contain users where the username contains this value
     * @param {number} [page] Retrieve a specific page, all when not set
     * @param {number} [pageSize] Page size of the page to return, when page is not set, this is ignored
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userListUsers(
      nameFilter?: string,
      page?: number,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userListUsers(
        nameFilter,
        page,
        pageSize,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UserApi.userListUsers"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {UserDto} [userDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userUpdateUser(
      userDto?: UserDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdateUser(
        userDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UserApi.userUpdateUser"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     *
     * @summary Create a new user
     * @param {UserDto} [userDto] User data of the user to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCreateUser(
      userDto?: UserDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserDto> {
      return localVarFp
        .userCreateUser(userDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a user from the scope system
     * @param {string} name username of the user to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDeleteUser(
      name: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .userDeleteUser(name, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns the list of assignable users (i.e. Factory / Office users)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetAssignableUsers(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<UserDto>> {
      return localVarFp
        .userGetAssignableUsers(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns a list of available users (in the active tenant context)
     * @param {string} [nameFilter] Filter the returned list to only contain users where the username contains this value
     * @param {number} [page] Retrieve a specific page, all when not set
     * @param {number} [pageSize] Page size of the page to return, when page is not set, this is ignored
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userListUsers(
      nameFilter?: string,
      page?: number,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<UserDto>> {
      return localVarFp
        .userListUsers(nameFilter, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserDto} [userDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUpdateUser(
      userDto?: UserDto,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .userUpdateUser(userDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @summary Create a new user
   * @param {UserDto} [userDto] User data of the user to be created
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userCreateUser(userDto?: UserDto, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userCreateUser(userDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a user from the scope system
   * @param {string} name username of the user to be deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userDeleteUser(name: string, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userDeleteUser(name, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns the list of assignable users (i.e. Factory / Office users)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userGetAssignableUsers(options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userGetAssignableUsers(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns a list of available users (in the active tenant context)
   * @param {string} [nameFilter] Filter the returned list to only contain users where the username contains this value
   * @param {number} [page] Retrieve a specific page, all when not set
   * @param {number} [pageSize] Page size of the page to return, when page is not set, this is ignored
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userListUsers(
    nameFilter?: string,
    page?: number,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return UserApiFp(this.configuration)
      .userListUsers(nameFilter, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserDto} [userDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userUpdateUser(userDto?: UserDto, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userUpdateUser(userDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
