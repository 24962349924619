import { FC, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { ISortable } from './utils/interfaces';
import { classNames } from 'primereact/utils';

type Props = {
  id: string;
  index: number;
  onDrag: (sortable: ISortable) => void;
  className?: string;
};

interface DragItem {
  type: string;
  id: string;
  index: number;
}

const SORTABLE_TYPE = 'FormField';

const Sortable: FC<Props> = ({ id, children, className, index, onDrag }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: SORTABLE_TYPE,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onDrag({
        dragIndex,
        hoverIndex,
      });

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: SORTABLE_TYPE,
    item: {
      id,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const divClassName = classNames('draggable-div', { 'is-dragging': isDragging }, className);
  drag(drop(ref));

  return (
    <div ref={ref} className={divClassName}>
      {children}
    </div>
  );
};

export default Sortable;
